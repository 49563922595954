
const DeleteSlotsDate = ({
  isVisible,
  setIsVisible,
  index,
  setSlotDate,
}) => {
  const handleDeleteSlotDate = () => {
    setSlotDate((prevSlotDate) => prevSlotDate.filter((_, i) => i !== index));
    setIsVisible(!isVisible);
  };

  return (
    <div className="delete-session-popup-overlay">
      <div className="delete-session-popup-content">
        <div>Are you sure want to delete ?</div>
        <div className="modal-buttons">
          <a className="btn btn-no" onClick={() => setIsVisible(!isVisible)}>
            No
          </a>
          <a className="btn btn-yes" onClick={handleDeleteSlotDate}>
            Yes
          </a>
        </div>
      </div>
    </div>
  );
};

export default DeleteSlotsDate;
