import { NavLink, useNavigate } from "react-router-dom";
import "../Navbar/Navbar.css";
import "./Login.css";
import { useEffect, useState } from "react";
import img from "../../assets/sign-in-back.svg";
import axios from "axios";
import { handleError, handleSuccess } from "../../Utils";
import { useAuth } from "../../provider/authProvider";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const {setToken} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Codequeue - Login";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("http://localhost:4000/api/v1/profiles", {userId});
      const role = res.data.role;
      // checking if profile role is user or mentor 
      if(role==='user'){
        const response = await axios.post("http://localhost:4000/api/v1/login", {
          userId,
          password,
        });
  
        const { jwtToken, message, name, success } = response.data;
  
        if (success) {
          handleSuccess(message);
          setToken(jwtToken);
          localStorage.setItem("loggedInUser", name);
          localStorage.setItem("role" , role)
          setUserId("");
          setPassword("");
          navigate('/dashboard');
        }
      }
      if(role==='mentor'){
        const response = await axios.post("http://localhost:4000/api/v1/mentor-login", {
          userId,
          password,
        });
  
        const { jwtToken, message, name, success, email } = response.data;
  
        if (success) {
          handleSuccess(message);
          setToken(jwtToken);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("loggedInUser", name);
          localStorage.setItem("role" , role)
          setUserId("");
          setPassword("");
          navigate('/dashboard');
        }
      }

    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  return (
    <div className="flex container login margin-top">
      <div className="left flex-col">
        <div>
          <form onSubmit={handleSubmit} className="login-form flex-col">
            <div className="form-group flex-col">
              <label htmlFor="email">Email/Phone : </label>
              <input
                type="text"
                id="userId"
                value={userId}
                placeholder="Email/Phone"
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>

            <div className="form-group flex-col">
              <label htmlFor="password">Password : </label>
              <input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button type="submit" className="primary-btn submit-btn">
              Login
            </button>
            <NavLink to={"/signup"} className="forgot-password hover">
              Don't have an account?
            </NavLink>
          </form>
        </div>
        <div className="divider">
          <hr className="divider-line" />
        </div>
        <NavLink to={"/forgot-password"} className="forgot-password hover">
          Forgot Password
        </NavLink>
      </div>

      <div className="right">
        <img src={img} alt="Img" />
      </div>
    </div>
  );
};

export default Login;
