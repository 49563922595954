import React, { useEffect } from "react";
import "./DeleteSession.css";
import axios from "axios";
import { handleError, handleSuccess } from "../../../../Utils";

const DeleteSession = ({ email, index, isVisible, setIsVisible }) => {
  const handleDelete = async () => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/v1/new-session",
        { email, index }
      );
      setIsVisible(false);
      handleSuccess(response.data.message);
      window.location.reload();
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  return (
    <div className="delete-session-popup-overlay">
      <div className="delete-session-popup-content">
        <div>Are you sure want to delete ?</div>
        <div className="modal-buttons">
          <a className="btn btn-no" onClick={() => setIsVisible(!isVisible)}>
            No
          </a>
          <a className="btn btn-yes" onClick={handleDelete}>Yes</a>
        </div>
      </div>
    </div>
  );
};

export default DeleteSession;
