import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Layout from '../Components/Layout'; 
import Home from '../Components/Home/Home';
import Signup from '../Components/Signup/Signup';
import Login from '../Components/Login/Login';
import ForgotPassword from '../Components/Login/ForgotPassword';
import Dashboard from '../Components/Dashboard/Dashboard';
import DashboardHome from '../Components/Dashboard/Home.jsx/Home';
import Booking from '../Components/Dashboard/Bookings/Booking';
import Priority from '../Components/Dashboard/PriorityDM/Priority';
import Payments from '../Components/Dashboard/Payments/Payments';
import User from '../Components/Dashboard/Bookings/User';
import BookTimeSlot from "../Components/Dashboard/Services/BookTimeSlot/BookTimeSlot";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import PrivacyAndPolicy from "../Pages/PrivacyAndPolicy/PrivacyAndPolicy";
import RefundPolicy from "../Pages/RefundPolicy/RefundPolicy";
import MentorDetails from "../Components/Mentor/MentorDetails/MentorDetails";
import Contact from "../Components/Contact/Contact";
import MentorOnboarding from "../Components/Signup/MentorOnboarding/MentorOnboarding";
import CreateNewPassword from "../Components/Login/CreateNewPassword";
import Sessions from "../Components/Dashboard/Services/Sessions";
import MentorsList from "../Components/Mentor/MentorsList/MentorsList";


const Routes = () => {
    const { token } = useAuth();

    const routesForPublic = [ 
        {
            path: "/",
            element: <Layout><Home /></Layout>, // Wrap with Layout
        },
        {
            path: "*",
            element: <Layout><PageNotFound/></Layout>
        },
        {
            path : "/privacy-and-policy",
            element : <Layout><PrivacyAndPolicy/></Layout>
        },
        {
            path : "/refund-policy",
            element : <Layout><RefundPolicy/></Layout>
        },
        {
            path : "/mentors-list",
            element : <Layout><MentorsList/></Layout>,
            children: [
                {
                    path: "details/:id", 
                    element: <MentorDetails/>
                }
            ]
        },
        {
            path: "/contact",
            element: <Layout><Contact/></Layout>
        },
        {
            path : "/onboarding",
            element: <Layout><MentorOnboarding/></Layout>
        }
    ];

    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />,
            children: [
                {
                    path: "/dashboard",
                    element: <Layout><Dashboard /></Layout>, // Wrap with Layout
                    children: [
                        {
                            index: true, // This indicates that this route is the default for its parent
                            element: <Navigate replace to="home" />, // Redirects to 'home'
                        },
                        {
                            path: "home",
                            element: <DashboardHome />, 
                        },
                        {
                            path: "bookings",
                            element: <Booking />, 
                        },
                        {
                            path: "bookings/:id",
                            element: <User />
                        },
                        {
                            path: "sessions",
                            element: <Sessions />
                        },
                        {
                            path: "services/booktimeslot",
                            element: <BookTimeSlot />
                        },
                        {
                            path: "prioritydm",
                            element: <Priority />
                        },
                        {
                            path: "payments",
                            element: <Payments />
                        },
                    ],
                },
            ],
        },
    ];

    const routesForNotAuthenticatedOnly = [
      
        {
            path: "/login",
            element: <Layout><Login /></Layout>, // Wrap with Layout
        },
        {
            path: "/signup",
            element: <Layout><Signup /></Layout>, // Wrap with Layout
        },
        {
            path: "/forgot-password",
            element:<Layout><ForgotPassword/></Layout>
        },
        {
            path: "/new-password",
            element : <Layout><CreateNewPassword/></Layout>
        }
    ];

    const router = createBrowserRouter([
        ...routesForPublic,
        ...routesForAuthenticatedOnly,
        ...(!token ? routesForNotAuthenticatedOnly : []),

    ]);

    return <RouterProvider router={router} />;
};

export default Routes;
