import React, { useState, useEffect, useRef } from "react";
import "./Profile.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import dummyProfileImage from "../../../assets/dummy-profile-pic.png";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  companyOptions,
  countryOptions,
  degreeOptions,
  designationOptions,
  domainOptions,
  languageOptions,
  skillsOptions,
  stateOptions,
} from "../../../Data/SelectOptions";
import { handleError, handleSuccess } from "../../../Utils";
import axios from "axios";
const experienceOptions = Array.from({ length: 31 }, (_, i) => ({
  value: `${i}+ ${i === 1 ? "Year" : "Years"}`,
  label: `${i}+ ${i === 1 ? "Year" : "Years"}`,
}));

const Profile = ({ isVisible, setIsVisible }) => {
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [profileImage, setProfileImage] = useState({
    url: "",
    filename: "",
    contentType: "",
    size: 0,
  });
  const [passingYear, setPassingYear] = useState(null);
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [heading, setHeading] = useState("");
  const [college, setCollege] = useState("");

  const email = localStorage.getItem("userEmail");
  const calledOnce = useRef(false);
  useEffect(() => {
    if (calledOnce.current) return; // Exit if already called
    calledOnce.current = true;
    const mentorProfileDetails = async () => {
      try {
        const response = await axios.post(
          "http://localhost:4000/api/v1/mentor-profile-details",
          { email }
        );
        setName(response.data.name);
        setHeading(response.data.heading);
        setSelectedDomain({
          label: response.data.selectedDomain,
          value: response.data.selectedDomain,
        });
        setAbout(response.data.about);
        setSelectedCountry({
          label: response.data.selectedCountry,
          value: response.data.selectedCountry,
        });
        setSelectedState({
          label: response.data.selectedState,
          value: response.data.selectedState,
        });
        setSelectedLanguage({
          label: response.data.selectedLanguage,
          value: response.data.selectedLanguage,
        });
        setSelectedCompany({
          label: response.data.currentCompany,
          value: response.data.currentCompany,
        });
        setSelectedDesignation({
          label: response.data.selectedDesignation,
          value: response.data.selectedDesignation,
        });
        // Map the skills array to the structure expected by the Select component
        const mappedSkills = response.data.selectedSkills.map((skill) => ({
          label: skill,
          value: skill,
        }));
        setSelectedSkills(mappedSkills);
        setSelectedExperience({
          label: response.data.selectedExperience,
          value: response.data.selectedExperience,
        });
        setCollege(response.data.college);
        setSelectedDegree({
          label: response.data.selectedDegree,
          value: response.data.selectedDegree,
        });
        setPassingYear(dayjs(response.data.passingYear));
        // Set the profileImage object
        if (response.data.profileImage) {
          setProfileImage({
            url: response.data.profileImage.url,
            filename: response.data.profileImage.filename,
            contentType: response.data.profileImage.contentType,
            size: response.data.profileImage.size,
          });
        }
      } catch (error) {
        return handleError(error.response.data.message);
      }
    };

    mentorProfileDetails();
  }, []);

  const closePopup = () => {
    setIsVisible(!isVisible);
  };

  const handleAboutChange = (event) => {
    if (event.target.value.length <= 750) {
      setAbout(event.target.value);
    }
  };
  const handleHeadingChange = (event) => {
    if (event.target.value.length <= 250) {
      setHeading(event.target.value);
    }
  };
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(null);
  };
  const handleCollegeChange = (event) => {
    if (event.target.value.length <= 100) {
      setCollege(event.target.value);
    }
  };

  const handleSubmit = async () => {
    // Check if a new profileImage is being uploaded and if its size is valid
    if (profileImage && profileImage.size && profileImage.size > 200 * 1024) {
      return handleError("Image size should be less than 200kb.");
    }
    // Check if any fields are missing
    if (
      !name ||
      !about ||
      !heading ||
      !college ||
      !selectedDomain ||
      !selectedCountry ||
      !selectedState ||
      !selectedLanguage ||
      !selectedCompany ||
      !selectedDesignation ||
      selectedSkills.length === 0 ||
      !selectedDegree ||
      !passingYear
    ) {
      return handleError("All Fields are required");
    } else {
      try {
        const res = await axios.post(
          "http://localhost:4000/api/v1/mentor-profile-details",
          {
            email,
            profileImage,
            name,
            about,
            heading,
            college,
            selectedDomain: selectedDomain.value,
            selectedCountry: selectedCountry.value,
            selectedState: selectedState.value,
            selectedLanguage: selectedLanguage.value,
            selectedCompany: selectedCompany.value,
            selectedDesignation: selectedDesignation.value,
            selectedSkills: selectedSkills.map((skill) => skill.value),
            selectedExperience: selectedExperience.value,
            selectedDegree: selectedDegree.value,
            passingYear,
          }
        );
        handleSuccess(res.data.message);
        localStorage.setItem("loggedInUser", name);
        setIsVisible(false);
        setTimeout(() => {
          window.location.reload(); // Refreshes the page after successful submission
        }, 800);
      } catch (error) {
        console.log(error);
        return handleError(error.response.data.message);
      }
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    // setProfileImage({...profileImage, profileImage : base64})

    if (file) {
      setProfileImage({
        url: base64,
        filename: file.name,
        contentType: file.type,
        size: file.size,
      });
    }
  };

  return (
    <>
      {isVisible && (
        <div className="mentor-profile-overlay">
          <div className="mentor-profile-popup flex-col">
            <div className="mentor-profile-upper flex">
              <aside className="mentor-profile-sidebar flex-col">
                <div className="flex-col">
                  <span className="ico">
                    <AccountCircleIcon />
                  </span>{" "}
                  <span>Profile</span>
                </div>
                {/* <div className="flex-col">
                  <span className="ico">
                    <CreditCardIcon />
                  </span>
                  <span>Pricing</span>
                </div>
                <div className="flex-col">
                  <span className="ico">
                    <EventAvailableIcon />
                  </span>
                  <span>Availability</span>
                </div> */}
              </aside>
              <div className="vertical-divider"></div>
              <main className="mentor-profile-main">
                <div className="header-text">Profile</div>
                <div className="horizontal-divider"></div>
                <div className="profile-scrollable">
                  <div className="flex-col">
                    <div className="background-cover">
                      <div className="background-cover-upper">
                        {/* <img
                          src={img}
                          alt=""
                          loading="lazy"
                          height="100px"
                          width="92px"
                        /> */}
                        <label htmlFor="file-upload">
                          <img
                            src={profileImage.url || dummyProfileImage}
                            alt=""
                            height="100px"
                            width="92px"
                          />
                        </label>
                        <input
                          type="file"
                          label="Image"
                          name="myfile"
                          id="file-upload"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </div>
                    </div>
                    <div className="profile-name profile-label-input">
                      <div className="profile-text">Full Name</div>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="profile-input-field"
                      />
                    </div>
                    <div className="profile-heading profile-label-input">
                      <div className="profile-text flex">
                        <span>Profile Heading</span>
                        <span>{heading ? heading.length : 0}/250</span>
                      </div>
                      <input
                        type="text"
                        className="profile-input-field"
                        value={heading}
                        onChange={handleHeadingChange}
                      />
                    </div>
                    <div className="what-do-you-do profile-label-input">
                      <div className="profile-text">What do you do?</div>
                      <Select
                        options={domainOptions}
                        isSearchable
                        value={selectedDomain}
                        isClearable
                        onChange={(selectedOption) =>
                          setSelectedDomain(selectedOption)
                        }
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>
                    <div className="about-mentor profile-label-input">
                      <div className="profile-text flex">
                        <span>About</span>
                        <span>{about ? about.length : 0}/750</span>
                      </div>
                      <textarea
                        className="profile-input-text-area "
                        value={about}
                        onChange={handleAboutChange}
                      ></textarea>
                    </div>
                    <div className="mentor-location profile-label-input">
                      <div className="profile-text">Location</div>
                      <div className="flex">
                        <Select
                          options={countryOptions}
                          isSearchable
                          className="profile-text-select"
                          value={selectedCountry}
                          isClearable
                          onChange={handleCountryChange}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              boxShadow: "none",
                            }),
                          }}
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                        <Select
                          options={stateOptions}
                          isSearchable
                          className="profile-text-select"
                          value={selectedState}
                          isDisabled={!selectedCountry}
                          isClearable
                          placeholder="State"
                          onChange={(selectedOption) =>
                            setSelectedState(selectedOption)
                          }
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              boxShadow: "none",
                            }),
                          }}
                          menuPlacement="auto"
                          menuPosition="fixed"
                        />
                      </div>
                    </div>
                    <div className="lang-you-speak profile-label-input">
                      <div className="profile-text">Native Language</div>
                      <Select
                        options={languageOptions}
                        isSearchable
                        isClearable
                        value={selectedLanguage}
                        onChange={(selectedOption) =>
                          setSelectedLanguage(selectedOption)
                        }
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <div className="profile-label-input">
                      <div className="profile-text">Current Company</div>
                      <Select
                        options={companyOptions}
                        isSearchable
                        isClearable
                        value={selectedCompany}
                        onChange={(selectedOption) =>
                          setSelectedCompany(selectedOption)
                        }
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <div className="profile-label-input">
                      <div className="profile-text">Designation</div>
                      <Select
                        options={designationOptions}
                        isSearchable
                        value={selectedDesignation}
                        isClearable
                        onChange={(selectedOption) =>
                          setSelectedDesignation(selectedOption)
                        }
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <div className="profile-label-input">
                      <div className="profile-text">Skills</div>
                      <Select
                        options={skillsOptions}
                        isSearchable
                        value={selectedSkills}
                        isMulti // Enable multiple selections
                        onChange={(selectedOption) =>
                          setSelectedSkills(selectedOption)
                        }
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <div className="profile-label-input">
                      <div className="profile-text">Experience(year)</div>
                      <Select
                        options={experienceOptions}
                        placeholder="Select Experience"
                        isClearable
                        isSearchable
                        value={selectedExperience}
                        onChange={setSelectedExperience}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    {/* Education section */}

                    <h3 className="education-section">Education</h3>

                    <div className="education-label-input">
                      <div className="profile-text flex">
                        <span>School or Institution</span>
                        <span>{college ? college.length : 0}/100</span>
                      </div>
                      <input
                        type="text"
                        className="profile-input-field"
                        value={college}
                        onChange={handleCollegeChange}
                      />
                    </div>

                    <div className="profile-label-input">
                      <div className="profile-text">Degree</div>
                      <Select
                        options={degreeOptions}
                        isSearchable
                        value={selectedDegree}
                        onChange={(selectedOption) =>
                          setSelectedDegree(selectedOption)
                        }
                        isClearable
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            boxShadow: "none",
                          }),
                        }}
                        menuPlacement="auto"
                        menuPosition="fixed"
                      />
                    </div>

                    <div className="mentor-edu-start-end-date profile-label-input flex">
                      {/* <div className="flex-col">
                        <div className="profile-text">Start Date</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            format="MMMM YYYY"
                            views={["year", "month"]}
                          />
                        </LocalizationProvider>
                      </div> */}
                      <div className="flex-col">
                        <div className="profile-text">Passing year</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={passingYear}
                            onChange={(date) => setPassingYear(date)}
                            format="MMMM YYYY"
                            views={["year", "month"]}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="horizontal-divider"></div>
            <div className="mentor-profile-lower flex">
              <div>
                <a className="secondary-btn" onClick={closePopup}>
                  Close
                </a>
                <a className="primary-btn" onClick={handleSubmit}>
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
