import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    // State to hold the authentication token
    const [token, setToken_] = useState(localStorage.getItem("token"));

    // Function to set the authentication token
    const setToken = (newToken) => {
        setToken_(newToken);
    };
    const removeToken = () => {
        setToken_(undefined)
        localStorage.removeItem('loggedInUser');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('role');
    };
    const isLoggedIn = () => {
        return !!token; // Returns true if token exists
    };
    const getUserName = () => {
        return localStorage.getItem('loggedInUser'); // Returns true if token exists
    };


    useEffect(() => {
        if (token) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            localStorage.setItem('token', token);
        } else {
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem('token')
        }
    }, [token]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            isLoggedIn,
            setToken,
            removeToken,
            getUserName,
        }),
        [token]
    );

    // Provide the authentication context to the children components
    return (
        <>
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>

        </>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;