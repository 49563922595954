import { React, useState, useEffect } from "react";
import "./Contact.css";
import { IoIosMail } from "react-icons/io";
import { TbMessageFilled } from "react-icons/tb";
import instaImg from "../../assets/instagram.svg";
import linkedInImg from "../../assets/linked-in.svg";
import twitterImg from "../../assets/twitter.svg";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { handleError, handleSuccess } from "../../Utils";

const Contact = () => {
  // PLEASE DO NOT MERGE FOR PRODUCTION *****************
  const REACT_APP_SITE_KEY = "6LdI61UqAAAAAHvNSnDXpkaOOwl1N5Yy1MzbbtCI";
  // console.log("Site Key:", process.env.REACT_APP_SITE_KEY);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    document.title = "Codequeue - Contact";
  }, []);

  const [captchaVerified, setCaptchaVerified] = useState(false); // change to false later on

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptcha = async (value) => {
    setCaptchaVerified(true);
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captchaVerified) {
      // Handle form submission here
      try {
        const response = await axios.post(
          "http://localhost:4000/api/v1/send-mail",
          {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            subject: formData.subject,
            message: formData.message,
          }
        );
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        return handleSuccess(response.data.message);
      } catch (error) {
        return handleError(error.response.data.message);
      }
    } else {
      handleError("Please verify you are human!");
    }
  };

  const formStyle = {
    maxWidth: "500px",
    margin: "auto",
    padding: "20px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  const inputStyle = {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  };

  return (
    <div className="mt-md-5 mx-md-5 mx-3 mt-sm-4 p-md-5 m-3 p-3">
      <div className="glass-effect d-flex flex-lg-row flex-column w-100 py-5 overflow-hidden gap-5 align-items-center">
        {/* Left part */}
        <div className="col-lg-6">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <h2 className="m-0">Send us a message...</h2>
            </div>
            <div className="display-6">
              <TbMessageFilled />
            </div>
          </div>

          {/* User Contact Form */}
          <div className="mt-3">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-md-row flex-column gap-md-3">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="form-input"
                />

                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </div>
              <div className="d-flex flex-md-row flex-column gap-md-3">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Your Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="form-input"
                />

                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </div>
              <div className="">
                <textarea
                  cols="70"
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="form-textarea w-100"
                />
              </div>

              <div className="d-flex flex-xl-row flex-column align-items-center mt-2 justify-content-around gap-sm-3">
                {/* CAPTCHA CODE HERE */}
                <div className="">
                  <ReCAPTCHA
                    sitekey={REACT_APP_SITE_KEY}
                    onChange={handleCaptcha}
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    disabled={!captchaVerified}
                    className="form-button px-5 py-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Right Part */}
        <div className="col-lg-6">
          <div>
            <div className="d-flex align-items-center justify-content-center w-100">
              <h2 className="m-0">Contact Information</h2>
            </div>

            <div className="d-flex flex-col justify-content-center align-items-center mt-5">
              <div className="display-6">
                <IoIosMail />
              </div>
              <div>
                <a
                  href="mailto:support@codequeue.in"
                  target="_blank"
                  class="font-bold"
                >
                  support@codequeue.in
                </a>
              </div>
            </div>

            {/* Social Media Links */}
            <div className="my-5 d-flex justify-content-center gap-3">
              <a a href="" target="_blank">
                {/* linkedIn link required */}
                <img src={linkedInImg} alt="linkedIn-Logo" />
              </a>
              <a a href="" target="_blank">
                {/* insta link required */}
                <img src={instaImg} alt="insta-Logo" />
              </a>
              <a a href="" target="_blank">
                {/* twitter link required */}
                <img src={twitterImg} alt="twitter-Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
