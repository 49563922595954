import { useState } from "react";
import Select from "react-select";

const ChooseTime = ({ isVisible, setIsVisible, setSlotDate, index }) => {
  const [selectedTime, setSelectedTime] = useState(null);

  // Generate time options in 12-hour format with 5-minute intervals
  const generateTimeOptions = () => {
    const times = [];
    let hours = 0;
    let minutes = 0;

    while (hours < 24) {
      const period = hours < 12 ? "AM" : "PM";
      const displayHour = hours % 12 === 0 ? 12 : hours % 12;
      const displayMinute = minutes < 10 ? `0${minutes}` : minutes;

      times.push({
        value: `${displayHour}:${displayMinute} ${period}`,
        label: `${displayHour}:${displayMinute} ${period}`,
      });

      minutes += 5;
      if (minutes === 60) {
        minutes = 0;
        hours++;
      }
    }

    return times;
  };

  const timeOptions = generateTimeOptions();

  const handleSlotTime = (time) => {
    if (time) {
      setSlotDate((prevSlotDate) => {
        const updatedSlotDate = [...prevSlotDate];
        const selectedDateEntry = updatedSlotDate[index];

        // Check for duplicates in the specific date's time array
        const isDuplicate = selectedDateEntry.time.some((slot) => slot.value === time.value);
        if (!isDuplicate) {
          // Add the new time and sort
          const updatedTimes = [...selectedDateEntry.time, time];
          const convertTo24HourFormat = (timeString) => {
            const [hourMinute, period] = timeString.value.split(" ");
            const [hour, minute] = hourMinute.split(":").map(Number);

            let newHour = hour;
            if (period === "PM" && hour !== 12) {
              newHour += 12;
            }
            if (period === "AM" && hour === 12) {
              newHour = 0;
            }
            return newHour * 60 + minute;
          };

          // Sort updatedTimes in ascending order
          selectedDateEntry.time = updatedTimes.sort((a, b) => {
            const timeA = convertTo24HourFormat(a);
            const timeB = convertTo24HourFormat(b);
            return timeA - timeB;
          });

          // Update slot count based on the length of the time array
          selectedDateEntry.slot = selectedDateEntry.time.length;

          // Update the specific date entry
          updatedSlotDate[index] = selectedDateEntry;
        }

        return updatedSlotDate;
      });

      // Clear selectedTime and close the popup
      setSelectedTime(null);
      setIsVisible(false);
    }
  };

  return (
    <div className="delete-session-popup-overlay">
      <div className="delete-session-popup-content">
        <label htmlFor="">Choose time</label>
        <Select
          options={timeOptions}
          placeholder=""
          isSearchable
          value={selectedTime}
          onChange={(selectedOption) => setSelectedTime(selectedOption)}
        />
        <div className="modal-buttons">
          <a className="btn btn-no" onClick={() => setIsVisible(!isVisible)}>
            Close
          </a>
          <a
            className="btn btn-yes"
            style={{ backgroundColor: "green" }}
            onClick={() => handleSlotTime(selectedTime)}
          >
            Ok
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChooseTime;
