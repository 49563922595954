
export const companyOptions = [
    { value: "Apple", label: "Apple" },
    { value: "Microsoft", label: "Microsoft" },
    { value: "Amazon", label: "Amazon" },
    { value: "Google", label: "Google" },
    { value: "Facebook (Meta Platforms)", label: "Facebook (Meta Platforms)" },
    { value: "Berkshire Hathaway", label: "Berkshire Hathaway" },
    { value: "Tesla", label: "Tesla" },
    { value: "Tencent", label: "Tencent" },
    { value: "Alibaba", label: "Alibaba" },
    { value: "Samsung Electronics", label: "Samsung Electronics" },
    { value: "Visa", label: "Visa" },
    { value: "Johnson & Johnson", label: "Johnson & Johnson" },
    { value: "JPMorgan Chase", label: "JPMorgan Chase" },
    { value: "Procter & Gamble", label: "Procter & Gamble" },
    { value: "Walmart", label: "Walmart" },
    { value: "Nestlé", label: "Nestlé" },
    { value: "Roche", label: "Roche" },
    { value: "Toyota Motor", label: "Toyota Motor" },
    { value: "Nvidia", label: "Nvidia" },
    { value: "TSMC (Taiwan Semiconductor)", label: "TSMC (Taiwan Semiconductor)" },
    { value: "LVMH (Moët Hennessy Louis Vuitton)", label: "LVMH (Moët Hennessy Louis Vuitton)" },
    { value: "Bank of America", label: "Bank of America" },
    { value: "UnitedHealth Group", label: "UnitedHealth Group" },
    { value: "Pfizer", label: "Pfizer" },
    { value: "Intel", label: "Intel" },
    { value: "Coca-Cola", label: "Coca-Cola" },
    { value: "ExxonMobil", label: "ExxonMobil" },
    { value: "AT&T", label: "AT&T" },
    { value: "PepsiCo", label: "PepsiCo" },
    { value: "Comcast", label: "Comcast" },
    { value: "Cisco Systems", label: "Cisco Systems" },
    { value: "Verizon Communications", label: "Verizon Communications" },
    { value: "Chevron", label: "Chevron" },
    { value: "Nike", label: "Nike" },
    { value: "Adobe", label: "Adobe" },
    { value: "IBM", label: "IBM" },
    { value: "Oracle", label: "Oracle" },
    { value: "SAP", label: "SAP" },
    { value: "Walt Disney", label: "Walt Disney" },
    { value: "Qualcomm", label: "Qualcomm" },
    { value: "McDonald's", label: "McDonald's" },
    { value: "Unilever", label: "Unilever" },
    { value: "Sony", label: "Sony" },
    { value: "Novartis", label: "Novartis" },
    { value: "General Electric", label: "General Electric" },
    { value: "HSBC", label: "HSBC" },
    { value: "Abbott Laboratories", label: "Abbott Laboratories" },
    { value: "Ford Motor", label: "Ford Motor" },
    { value: "Honeywell International", label: "Honeywell International" },
    { value: "Boeing", label: "Boeing" },
    { value: "Accenture", label: "Accenture" },
    { value: "Cognizant", label: "Cognizant" },
    { value: "TCS (Tata Consultancy Services)", label: "TCS (Tata Consultancy Services)" },
    { value: "Infosys", label: "Infosys" },
    { value: "Wipro", label: "Wipro" },
    { value: "HCL Technologies", label: "HCL Technologies" },
    { value: "Tech Mahindra", label: "Tech Mahindra" },
    { value: "Capgemini", label: "Capgemini" },
    { value: "IBM Global Services", label: "IBM Global Services" },
    { value: "DXC Technology", label: "DXC Technology" },
    { value: "L&T Infotech", label: "L&T Infotech" },
    { value: "Mindtree", label: "Mindtree" },
    { value: "NTT Data", label: "NTT Data" },
    { value: "Cimpress", label: "Cimpress" },
    { value: "Cognizant Technology Solutions", label: "Cognizant Technology Solutions" },
    { value: "Oracle Consulting", label: "Oracle Consulting" },
    { value: "SAP Services", label: "SAP Services" },
    { value: "Altran", label: "Altran" },
    { value: "KPMG", label: "KPMG" },
    { value: "Deloitte", label: "Deloitte" },
    { value: "PwC (PricewaterhouseCoopers)", label: "PwC (PricewaterhouseCoopers)" },
    { value: "Ernst & Young (EY)", label: "Ernst & Young (EY)" },
    { value: "Mphasis", label: "Mphasis" },
    { value: "Fujitsu", label: "Fujitsu" },
    { value: "Accenture Song", label: "Accenture Song" },
    { value: "Genpact", label: "Genpact" },
    { value: "Zensar Technologies", label: "Zensar Technologies" },
    { value: "Birlasoft", label: "Birlasoft" },
    { value: "Syntel", label: "Syntel" },
    { value: "Atos", label: "Atos" },
    { value: "Amdocs", label: "Amdocs" },
    { value: "Cognizant Softvision", label: "Cognizant Softvision" },
    { value: "Mediacom", label: "Mediacom" },
    { value: "Aon", label: "Aon" },
    { value: "ManpowerGroup", label: "ManpowerGroup" },
    { value: "Randstad", label: "Randstad" },
    { value: "Robert Half", label: "Robert Half" },
    { value: "Allegis Group", label: "Allegis Group" },
    { value: "Michael Page", label: "Michael Page" },
    { value: "Hays", label: "Hays" },
    { value: "Staffmark", label: "Staffmark" },
    { value: "Kelly Services", label: "Kelly Services" },
    { value: "Adecco", label: "Adecco" },
    { value: "Robert Walters", label: "Robert Walters" },
    { value: "Insight Global", label: "Insight Global" },
    { value: "Celerity", label: "Celerity" },
    { value: "Experis", label: "Experis" },
    { value: "Iconma", label: "Iconma" },
    { value: "Nelson Staffing", label: "Nelson Staffing" },
    { value: "Other", label: "Other" },
  ];

 export const domainOptions = [
    { value: "Frontend", label: "Frontend" },
    { value: "Backend", label: "Backend" },
    { value: "Full Stack", label: "Full Stack" },
    { value: "UI/UX", label: "UI/UX" },
    { value: "DevOps / SRE / Cloud", label: "DevOps / SRE / Cloud" },
    { value: "Cyber Security", label: "Cyber Security" },
    { value: "QA / Automation Testing", label: "QA / Automation Testing" },
    { value: "Data Engineer / Big Data", label: "Data Engineer / Big Data" },
    { value: "Data Scientist / AI/ML", label: "Data Scientist / AI/ML" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Sales", label: "Sales" },
    { value: "Marketing", label: "Marketing" },
    {
      value: "Business Analyst / Consultant",
      label: "Business Analyst / Consultant",
    },
    { value: "Finance", label: "Finance" },
    { value: "HR", label: "HR" },
  ];

  export const countryOptions = [{ value: "India", label: "India" }];
  export const stateOptions = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "West Bengal", label: "West Bengal" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
  ];
  
  export const languageOptions = [
    { value: "Assamese", label: "Assamese" },
    { value: "Bengali", label: "Bengali" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kannada", label: "Kannada" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Urdu", label: "Urdu" },
    { value: "Tulu", label: "Tulu" },
    { value: "Bhili", label: "Bhili" },
    { value: "Garo", label: "Garo" },
    { value: "Khasi", label: "Khasi" },
    { value: "Mizo", label: "Mizo" },
    { value: "Mundari", label: "Mundari" },
    { value: "Haryanvi", label: "Haryanvi" },
    { value: "Rajasthani", label: "Rajasthani" },
    { value: "Awadhi", label: "Awadhi" },
    { value: "Chhattisgarhi", label: "Chhattisgarhi" },
    { value: "Bhojpuri", label: "Bhojpuri" },
    { value: "Magahi", label: "Magahi" },
    { value: "Marwari", label: "Marwari" },
    { value: "Garhwali", label: "Garhwali" },
    { value: "Kumaoni", label: "Kumaoni" },
];

  export const designationOptions = [
    { value: 'Director', label: 'Director' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Assistant Manager', label: 'Assistant Manager' },
    { value: 'Team Lead', label: 'Team Lead' },
    { value: 'Project Manager', label: 'Project Manager' },
    { value: 'Product Manager', label: 'Product Manager' },
    { value: 'Operations Manager', label: 'Operations Manager' },
    { value: 'Associate', label: 'Associate' },
    { value: 'Analyst', label: 'Analyst' },
    { value: 'Coordinator', label: 'Coordinator' },
    { value: 'Specialist', label: 'Specialist' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Executive Assistant', label: 'Executive Assistant' },
    { value: 'Administrative Assistant', label: 'Administrative Assistant' },
    { value: 'Sales Representative', label: 'Sales Representative' },
    { value: 'Customer Service Representative', label: 'Customer Service Representative' },
    { value: 'Software Engineer', label: 'Software Engineer' },
    { value: 'Systems Analyst', label: 'Systems Analyst' },
    { value: 'Database Administrator', label: 'Database Administrator' },
    { value: 'Network Engineer', label: 'Network Engineer' },
    { value: 'Data Scientist', label: 'Data Scientist' },
    { value: 'Web Developer', label: 'Web Developer' },
    { value: 'DevOps Engineer', label: 'DevOps Engineer' },
    { value: 'HR Manager', label: 'HR Manager' },
    { value: 'Recruiter', label: 'Recruiter' },
    { value: 'Training and Development Specialist', label: 'Training and Development Specialist' },
    { value: 'Compensation and Benefits Analyst', label: 'Compensation and Benefits Analyst' },
    { value: 'Marketing Manager', label: 'Marketing Manager' },
    { value: 'Brand Manager', label: 'Brand Manager' },
    { value: 'Sales Manager', label: 'Sales Manager' },
    { value: 'Digital Marketing Specialist', label: 'Digital Marketing Specialist' },
    { value: 'Content Writer', label: 'Content Writer' },
    { value: 'Accountant', label: 'Accountant' },
    { value: 'Financial Analyst', label: 'Financial Analyst' },
    { value: 'Internal Auditor', label: 'Internal Auditor' },
    { value: 'Treasury Analyst', label: 'Treasury Analyst' },
    { value: 'Legal Counsel', label: 'Legal Counsel' },
    { value: 'Compliance Officer', label: 'Compliance Officer' },
    { value: 'Paralegal', label: 'Paralegal' },
    { value: 'CEO', label: 'Chief Executive Officer (CEO)' },
    { value: 'COO', label: 'Chief Operating Officer (COO)' },
    { value: 'CFO', label: 'Chief Financial Officer (CFO)' },
    { value: 'CMO', label: 'Chief Marketing Officer (CMO)' },
    { value: 'CTO', label: 'Chief Technology Officer (CTO)' },
    { value: 'CIO', label: 'Chief Information Officer (CIO)' },
    { value: 'CHRO', label: 'Chief Human Resources Officer (CHRO)' },
    { value: 'CCO', label: 'Chief Compliance Officer (CCO)' },
    { value: 'CSO', label: 'Chief Strategy Officer (CSO)' },
    { value: 'VP', label: 'Vice President (VP)' },
    { value: 'SVP', label: 'Senior Vice President (SVP)' },
  ];

  export const skillsOptions = [
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
    { value: 'C++', label: 'C++' },
    { value: 'C#', label: 'C#' },
    { value: 'PHP', label: 'PHP' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Go', label: 'Go' },
    { value: 'Swift', label: 'Swift' },
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'HTML', label: 'HTML' },
    { value: 'CSS', label: 'CSS' },
    { value: 'React', label: 'React' },
    { value: 'Angular', label: 'Angular' },
    { value: 'Vue', label: 'Vue' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'Express', label: 'Express' },
    { value: 'Django', label: 'Django' },
    { value: 'Flask', label: 'Flask' },
    { value: 'Ruby on Rails', label: 'Ruby on Rails' },
    { value: 'SQL', label: 'SQL' },
    { value: 'NoSQL', label: 'NoSQL' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'Git', label: 'Git' },
    { value: 'Docker', label: 'Docker' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'AWS', label: 'Amazon Web Services (AWS)' },
    { value: 'Azure', label: 'Microsoft Azure' },
    { value: 'Google Cloud', label: 'Google Cloud Platform' },
    { value: 'Machine Learning', label: 'Machine Learning' },
    { value: 'Deep Learning', label: 'Deep Learning' },
    { value: 'Data Analysis', label: 'Data Analysis' },
    { value: 'Data Visualization', label: 'Data Visualization' },
    { value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
    { value: 'Cyber Security', label: 'Cyber Security' },
    { value: 'DevOps', label: 'DevOps' },
    { value: 'UI/UX Design', label: 'UI/UX Design' },
    { value: 'SEO', label: 'Search Engine Optimization (SEO)' },
    { value: 'Digital Marketing', label: 'Digital Marketing' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Agile', label: 'Agile' },
    { value: 'Scrum', label: 'Scrum' },
    { value: 'Communication', label: 'Communication' },
    { value: 'Teamwork', label: 'Teamwork' },
    { value: 'Problem Solving', label: 'Problem Solving' },
    { value: 'Time Management', label: 'Time Management' },
    { value: 'Critical Thinking', label: 'Critical Thinking' },
    { value: 'Creativity', label: 'Creativity' },
  ];

  export const degreeOptions = [
    { value: 'B.Tech', label: 'B.Tech' },
    { value: 'B.E.', label: 'B.E.' },
    { value: 'B.Sc.', label: 'B.Sc.' },
    { value: 'B.A.', label: 'B.A.' },
    { value: 'B.Ed.', label: 'B.Ed.' },
    { value: 'B.Com', label: 'B.Com' },
    { value: 'BBA', label: 'BBA' },
    { value: 'BCA', label: 'BCA' },
    { value: 'BHM', label: 'BHM' },
    { value: 'B. Pharmacy', label: 'B. Pharmacy' },
    { value: 'M.Tech', label: 'M.Tech' },
    { value: 'M.E.', label: 'M.E.' },
    { value: 'M.Sc.', label: 'M.Sc.' },
    { value: 'M.A.', label: 'M.A.' },
    { value: 'M.Ed.', label: 'M.Ed.' },
    { value: 'M.Com', label: 'M.Com' },
    { value: 'MBA', label: 'MBA' },
    { value: 'MCA', label: 'MCA' },
    { value: 'M. Pharmacy', label: 'M. Pharmacy' },
    { value: 'MSW', label: 'MSW' },
    { value: 'Ph.D.', label: 'Ph.D.' },
    { value: 'Ed.D.', label: 'Ed.D.' },
    { value: 'D. Pharmacy', label: 'D. Pharmacy' },
    { value: 'D.Ed.', label: 'D.Ed.' },
    { value: 'PGDM', label: 'PGDM' },
    { value: 'PGDE', label: 'PGDE' },
  ];