import * as React from "react";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../../provider/authProvider";
import Profile from "../Dashboard/Profile/Profile";

function Appbar() {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState();
  const { removeToken, getUserName } = useAuth();
  const [isVisible, setIsVisible] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (key) => {
    switch (key) {
      case "Profile":
        setIsVisible(!isVisible);
        break;
      case "Account":
        break;
      case "Dashboard":
        navigate("/dashboard");
        break;
      case "Logout":
        removeToken();
        break;
      default:
        break;
    }
    setAnchorElUser(null);
  };

  return (
    <Toolbar disableGutters>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu}>
          <Avatar alt={getUserName()} src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={() => handleCloseUserMenu("Profile")}>
          <Typography>Profile</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseUserMenu("Account")}>
          <Typography>Account</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseUserMenu("Dashboard")}>
          <Typography>Dashboard</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseUserMenu("Logout")}>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
      <Profile isVisible={isVisible} setIsVisible={setIsVisible} />
    </Toolbar>
  );
}
export default Appbar;
