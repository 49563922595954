import './App.css';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import Home from './Components/Home/Home';
// import Navbar from './Components/Navbar/Navbar';
// import Contact from './Components/Contact/Contact';
// import Careers from './Components/Careers/Careers';
// import Signup from './Components/Signup/Signup';
// import Login from './Components/Login/Login';
// import Dashboard from './Components/Dashboard/Dashboard';
// import ForgotPassword from './Components/Login/ForgotPassword';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import Booking from './Components/Dashboard/Bookings/Booking';
// import Priority from './Components/Dashboard/PriorityDM/Priority';
// import DashboardHome from './Components/Dashboard/Home.jsx/Home';
// import Payments from './Components/Dashboard/Payments/Payments';
// import User from './Components/Dashboard/Bookings/User';
// import AuthProvider from "./provider/authProvider";
// import Routes from "./routes";

// function App() {
//   // const location = useLocation();

//   // Determine if Navbar and Footer should be hidden
//   // const hideNavbarFooter = location.pathname === '/login';

//   return (
//     <div className="App">
//       {<Navbar />}
//       <Routes>
//         <Route path='/' element={<Home />} />
//         <Route path='/about' element={<About />} />
//         <Route path='/contact' element={<Contact />} />
//         <Route path='/careers' element={<Careers />} />
//         <Route path='/login' element={<Login />} />
//         <Route path='/signup' element={<Signup />} />
//         <Route path='/dashboard' element={<Dashboard />}>
//           <Route index element={<Navigate replace to="home" />} />
//           <Route path='home' element={<DashboardHome />} />
//           <Route path='bookings' element={<Booking />} />
//           <Route path='bookings/:id' element={<User />} />
//           <Route path='prioritydm' element={<Priority />} />
//           <Route path='payments' element={<Payments />} />
//         </Route>
//         <Route path='/forgot-password' element={<ForgotPassword />} />
//       </Routes>
//       {<Footer />}
//       <NotificationContainer />
//       <ToastContainer />
//     </div>
//   );
// }

// // Wrapping App component with Router
// export default function AppWrapper() {
//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// }
import AuthProvider from "./provider/authProvider";
import Routes from "./routes";

function App() {

  return (
    <>
      <AuthProvider>
        <Routes />
      </AuthProvider>
      <ToastContainer />
      <NotificationContainer />
    </>

  );
}

export default App;