import React, { useState } from 'react';
import './ComingSoon.css';

const ComingSoon = ({isVisible, setIsVisible}) => {

  const closePopup = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="coming-soon-overlay">
          <div className="coming-soon-popup">
            <h1>Coming Soon!</h1>
            <p>We're working hard to bring this feature to you. Stay tuned!</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
      {/* Your other content goes here */}
    </>
  );
};

export default ComingSoon;
