import React, { useState } from "react";
import "./AvailableSlot.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from '@mui/icons-material/Close';

const AvailableSlot = ({ isVisible, setIsVisible }) => {

  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);

  const closePopup = () => {
    setIsVisible(false);
  };

  const bookNow = () =>{

  }

  const slotDate = [
    {
      day: "WED",
      date: "2 Oct",
      slot: 3,
    },
    {
      day: "TRU",
      date: "3 Oct",
      slot: 5,
    },
    {
      day: "FRI",
      date: "4 Oct",
      slot: 1,
    },
    {
      day: "SAT",
      date: "5 Oct",
      slot: 10,
    },
    {
      day: "SUN",
      date: "6 Oct",
      slot: 12,
    },
    {
      day: "MON",
      date: "7 Oct",
      slot: 4,
    },
    {
      day: "TUE",
      date: "8 Oct",
      slot: 2,
    },
  ];
  const slotTime = [
    {
      time: "08:30 PM",
    },
    {
      time: "09:30 PM",
    },
    {
      time: "10:30 PM",
    },
    {
      time: "11:30 PM",
    },
    {
      time: "12:30 PM",
    },
    {
      time: "01:30 PM",
    },
    {
      time: "02:30 PM",
    },
  ];

  const chooseTime = ((index)=>{
    setSelectedTimeIndex(index);
  })

  const chooseDate = ((index)=>{
    setSelectedDateIndex(index);
  })

  return (
    <>
      {isVisible && (
        <div className="book-slot-overlay">
          <div className="book-slot-popup flex-col">
            <div className="close-icon" onClick={closePopup}><CloseIcon /> </div>
            <div className="available-text">Available Dates</div>
            <div className="available-slots flex">
              {slotDate.map((item, index) => {
                return (
                  <div  onClick={()=>chooseDate(index)} className={`available-dates flex-col ${(selectedDateIndex===index)?"is-date-selected":""}`} key={index}>
                    <div className="day">{item.day}</div>
                    <div className="date">{item.date}</div>
                    <div className="slots">{item.slot} slots</div>
                  </div>
                );
              })}
            </div>
            <div className="divider"></div>
            <div className="available-date flex">
              <div className="">Available Slots</div>
              <div className="scroll-icon">
                {" "}
                <NavigateBeforeIcon />
                <NavigateNextIcon />{" "}
              </div>
            </div>
            <div className="available-slots flex">
              {slotTime.map((item, index) => {
                return (
                  <div onClick={()=>chooseTime(index)} className={`available-dates flex-col ${(selectedTimeIndex===index)?"is-date-selected":""}`} key={index}>
                    <div className="available-time">{item.time}</div>
                  </div>
                );
              })}
            </div>
            <div onClick={bookNow} className="primary-btn book-now">Book Now</div>
          </div>
        </div>
      )}
    </>
  );
};

export default AvailableSlot;
