import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./WeOffer.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Mentors from "../Mentor/Mentor";

const WeOffer = () => {

  const [isVisible , setIsVisible] = useState(false);

  const sessionType = [
    { type: "Mock Interview" },
    { type: "Interview prep & tips" },
    { type: "Career guidance" },
    { type: "1:1 Mentorship" },
    { type: "Resume Review" },
    { type: "Quick call" },
  ];

  return (
    <>
    <h1 className="we-offer-header">What we offer?</h1>
    <div className="we-offer flex">
      {sessionType.map((val, index) => {
        return (
          <div className="we-offer-card flex-col" key={index}>
            <div className="we-offer-left">{val.type}</div>
            <div className="we-offer-right flex">
              <div>
                <CalendarMonthIcon />
                Video Meeting
              </div>
              <Link className="primary-btn" onClick={()=>{setIsVisible(!isVisible)}}>Book Slot</Link>
            </div>
          </div>
        );
      })}
    </div>
    {isVisible && <Mentors setIsVisible={setIsVisible}/>}
    </>
  );
};

export default WeOffer;
