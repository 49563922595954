import React, { useEffect } from "react";
import Testimonials from "../Testimonials/Testimonials";
import HeroSection from "../Hero/HeroSection";
import './Home.css'
import FAQ from "../../Pages/Faqs/FAQ";
import WeOffer from "../WeOffer/WeOffer";

const Home = () => {
  useEffect(() => {
    document.title = "Codequeue - Connect with Experts for Personalized Guidance";
  }, []);
  return (
    <div className="home-page">
      <HeroSection/>
      <WeOffer/>
      <Testimonials />
      <FAQ/>
    </div>
  );
};

export default Home;
