import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../Navbar/Navbar.css";
import { useState } from "react";
import img from "../../assets/sign-in-back.svg";
import "./Signup.css";
import axios from "axios";
import { handleError, handleSuccess } from "../../Utils";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [mailOtp, setMailOtp] = useState("");
  const [numOtp, setNumOtp] = useState("");
  const [isEditableNum, setIsEditableNum] = useState(true);
  const [isEditableEmail, setIsEditableEmail] = useState(true);
  const [isNumOtpInputVisible, setIsNumOtpInputVisible] = useState(false);
  const [isEmailOtpInputVisible, setIsEmailOtpInputVisible] = useState(false);
  const [isFullDetails, SetIsFullDetails] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Codequeue - Signup";
  }, []);

  // send otp on email
  const emailOtpSend = async () => {
    try {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return handleError("Please Enter Valid Email.");
      }
      const response = await axios.post(
        "http://localhost:4000/api/v1/send-email-otp",
        { email }
      );
      setTimeout(() => {
        setIsEmailOtpInputVisible(true);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };
  // verify email otp
  const emailOtpVerify = async () => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/v1/verify-email-otp",
        { email, mailOtp }
      );
      setMailOtp("");
      setTimeout(() => {
        setIsEmailOtpInputVisible(false);
        setIsEditableEmail(false);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };
  // send otp on number
  const numOtpSend = async () => {
    try {
      const phoneRegex = /^\d{10}$/; // Ensures exactly 10 digits
      if (!phoneRegex.test(phone)) {
        return handleError("Phone number must be exactly 10 digits.");
      }
      const response = await axios.post(
        "http://localhost:4000/api/v1/send-otp",
        { phone }
      );
      setTimeout(() => {
        setIsNumOtpInputVisible(true);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };
  // verify num otp
  const numOtpVerify = async () => {
    try {
      const otp = numOtp;
      const response = await axios.post(
        "http://localhost:4000/api/v1/verify-otp",
        { phone, otp }
      );
      setNumOtp("");
      setTimeout(() => {
        setIsNumOtpInputVisible(false);
        setIsEditableNum(false);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFullDetails) {
      SetIsFullDetails(true);
    } else {
      if (!name || !email || !phone || !password) {
        return handleError("All fields are required");
      }

      if (name.length < 3) {
        return handleError("Name must be at least 3 characters long.");
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return handleError("Please Enter Valid Email.");
      }

      const phoneRegex = /^\d{10}$/; // Ensures exactly 10 digits
      if (!phoneRegex.test(phone)) {
        return handleError("Phone number must be exactly 10 digits.");
      }

      if (password.length < 6 || password.length > 12) {
        return handleError("Password must be between 6 and 12 characters.");
      }

      try {
        // creating new user profile
        const response = await axios.post(
          "http://localhost:4000/api/v1/signup",
          {
            name,
            email,
            phone,
            password,
          }
        );
        handleSuccess(response.data.message);
        setName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } catch (error) {
        return handleError(error.response.data.message);
      }
    }
  };

  const getback = (e) => {
    e.preventDefault();
    SetIsFullDetails(false);
  };

  return (
    <div className="flex login">
      <div className="left  signup-left flex-col">
        <div className="left-mid signup-left-top flex-col">
          <div>
            <h1>Create your profile</h1>
            <p>Surround Yourself with Brilliance—Start with CodeQueue.</p>
          </div>
        </div>

        <div>
          <form
            onSubmit={handleSubmit}
            className="login-form signup-form flex-col"
          >
            <div
              className={`form-group ${isFullDetails ? "is-full-details" : ""}`}
            >
              <label htmlFor="email">Email : </label>
              <div className="otp flex">
                <input
                  type="email"
                  id="email"
                  editInput
                  readOnly={!isEditableEmail}
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span onClick={emailOtpSend} className="otp-send flex">
                  Send
                </span>
              </div>
              <div
                className={`otp-verify flex ${
                  isEmailOtpInputVisible ? "" : "otp-toggle"
                }`}
              >
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={mailOtp}
                  onChange={(e) => setMailOtp(e.target.value)}
                />
                <span onClick={emailOtpVerify}>Verify</span>
              </div>
            </div>

            <div
              className={`form-group ${isFullDetails ? "is-full-details" : ""}`}
            >
              <label htmlFor="phone">Phone : </label>
              <div className="otp flex">
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  placeholder="Phone number"
                  readOnly={!isEditableNum}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <span onClick={numOtpSend} className="otp-send flex">
                  Send
                </span>
              </div>
              <div
                className={`otp-verify flex ${
                  isNumOtpInputVisible ? "" : "otp-toggle"
                }`}
              >
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={numOtp}
                  onChange={(e) => setNumOtp(e.target.value)}
                />
                <span onClick={numOtpVerify}>Verify</span>
              </div>
            </div>

            <div
              className={`form-group ${isFullDetails ? "" : "is-full-details"}`}
            >
              <label htmlFor="password">Name : </label>
              <input
                type="text"
                id="name"
                value={name}
                placeholder="Your name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div
              className={`form-group ${isFullDetails ? "" : "is-full-details"}`}
            >
              <label htmlFor="password">Password : </label>
              <input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="flex s-b-btn">
              <button
                onClick={getback}
                className={`primary-btn signup-btn submit-btn  ${
                  isFullDetails ? "" : "is-full-details"
                }`}
              >
                Back
              </button>
              <button
                type="submit"
                className="primary-btn signup-btn submit-btn"
              >
                {isFullDetails ? "Submit" : "Next"}
              </button>
            </div>
            <NavLink to={"/onboarding"} className="forgot-password hover">
              Signup As Mentor
            </NavLink>
            <div className="divider">
              <hr className="divider-line" />
            </div>
            <NavLink to={"/login"} className="forgot-password as-mentor hover">
              Already have an account?
            </NavLink>
          </form>
        </div>
      </div>

      <div className="right">
        <img src={img} alt="Img" />
      </div>
    </div>
  );
};

export default Signup;
