import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import { NavLink } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HeroSection = () => {
 
  const heroHeadears = [
    "Personalized mentorship for careers advancement",
    "Unlock your potential with tailored career guidance",
    "Expert mentorship designed for your professional growth",
    "Roadmap to success through personalized mentorship."
  ]
  const heroCards = [
    { name: "Satyendra Kumar", designation: "Software Engineer", company: "Capgemini Technology", tech: "MERN Stack", experience: "1yr", desc: "helps people get a job" },
    { name: "Satyendra Kumar", designation: "Software Engineer", company: "Capgemini Technology", tech: "MERN Stack", experience: "1yr", desc: "helps people get a job" },
  ];

  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0); // Current header index
  const [displayedText, setDisplayedText] = useState(""); // The text being typed out
  const [isDeleting, setIsDeleting] = useState(false); // Track if we're deleting or typing
  const [charIndex, setCharIndex] = useState(0); // Track the character being typed/removed
  const [typingSpeed, setTypingSpeed] = useState(50); // Speed of typing and erasing

  useEffect(() => {
    const currentHeader = heroHeadears[currentHeaderIndex];

    const handleTyping = () => {
      if (!isDeleting && charIndex < currentHeader.length) {
        // Typing letters
        setDisplayedText((prevText) => prevText + currentHeader.charAt(charIndex));
        setCharIndex((prevIndex) => prevIndex + 1);
      } else if (isDeleting && charIndex > 0) {
        // Deleting letters
        setDisplayedText((prevText) => prevText.slice(0, -1));
        setCharIndex((prevIndex) => prevIndex - 1);
      } else if (!isDeleting && charIndex === currentHeader.length) {
        // Pause at the end of typing
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && charIndex === 0) {
        // Switch to the next header
        setIsDeleting(false);
        setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % heroHeadears.length);
      }
    };

    const timeout = setTimeout(handleTyping, isDeleting ? typingSpeed / 2 : typingSpeed);

    return () => clearTimeout(timeout);
  }, [charIndex, isDeleting, currentHeaderIndex, heroHeadears, typingSpeed]);


  return (
    <div className="hero flex">
      <div className="hero-left flex-col">
        <h1 className="hero-header">
        {displayedText}
        </h1>
        <div className="hero-para">
          Elevate your expertise by learning from industry leaders. Start your
          journey towards success today.
        </div>
        <div className="hero-btn flex">
          <NavLink to={"/login"} className="secondary-btn hero-btn">Learn More <ArrowForwardIcon/></NavLink>
          <NavLink to={"/mentors-list"} className="primary-btn hero-btn"style={{ color: "white" }}>Book Session</NavLink>
        </div>
      </div>

      <div className="hero-right flex">
        {
          heroCards.map((item, index)=>{
            return (
              <div className="hero-card flex-col" key={index}>
                <div className="hero-image"></div>
                <div className="hero-name">{item.name}</div>
                <div className="hero-designation">{item.designation}</div>
                <div className="hero-company">{item.company}</div>
                <div className="hero-eoe"><span>Tech :</span>{item.tech}</div>
                <div className="hero-eoe"><span>Year of Ex :</span>{item.experience}</div>
                <div className="hero-desc"><span>Satyendra </span>{item.desc}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default HeroSection;
