export const users = [
    {
        id: '1',
        name: 'Ankit Sharma',
        category: 'Analyst',
        image: 'https://static.vecteezy.com/system/resources/previews/024/354/252/non_2x/businessman-isolated-illustration-ai-generative-free-photo.jpg',
        email: 'ankit.sharma@example.com',
        phone: '+91-9876543210',
        company: 'Infosys',
        exp: '3 years',
        college: 'Indian Institute of Technology, Delhi',
        degree: 'B.Tech in Computer Science',
        skills: ['Data Analysis', 'Python', 'SQL', 'Excel']
    },
    {
        id: '2',
        name: 'Rahul Yadav',
        category: 'SDE-I',
        image: 'https://static.vecteezy.com/system/resources/thumbnails/034/598/493/small/ai-generative-happy-business-man-in-a-suit-white-background-photo.jpg',
        email: 'rahul.yadav@example.com',
        phone: '+91-9123456789',
        company: 'Wipro',
        exp: '2 years',
        college: 'Indian Institute of Technology, Bombay',
        degree: 'B.Tech in Information Technology',
        skills: ['Java', 'C++', 'Data Structures', 'Algorithms']
    },
    {
        id: '3',
        name: 'Pankaj Kumar',
        category: 'SDE-II',
        image: 'https://media.istockphoto.com/id/685132245/photo/mature-businessman-smiling-over-white-background.jpg?s=612x612&w=0&k=20&c=OJk6U-oCZ31F3TGmarAAg2jVli8ZWTagAcF4P-kNIqA=',
        email: 'pankaj.kumar@example.com',
        phone: '+91-9234567890',
        company: 'Amazon',
        exp: '4 years',
        college: 'Birla Institute of Technology and Science, Pilani',
        degree: 'B.E. in Computer Science',
        skills: ['JavaScript', 'React', 'Node.js', 'AWS']
    },
    {
        id: '4',
        name: 'Ankita Saha',
        category: 'Software Engineer',
        image: 'https://media.istockphoto.com/id/1354842602/photo/portrait-of-a-young-businesswoman-working-on-a-laptop-in-an-office.jpg?s=612x612&w=0&k=20&c=kfP1g2712RiaxsDriIxFo363ARlaL2D591s-22CnIo8=',
        email: 'soham.saha@example.com',
        phone: '+91-9345678901',
        company: 'Microsoft',
        exp: '5 years',
        college: 'National Institute of Technology, Trichy',
        degree: 'B.Tech in Electronics and Communication Engineering',
        skills: ['C#', '.NET', 'Azure', 'Microservices']
    },
    {
        id: '5',
        name: 'White Carls',
        category: 'Graphic Designer',
        image: 'https://thumbs.dreamstime.com/b/young-professional-woman-15915020.jpg',
        email: 'white.carls@example.com',
        phone: '+91-9456789012',
        company: 'Adobe',
        exp: '4 years',
        college: 'Savannah College of Art and Design',
        degree: 'BFA in Graphic Design',
        skills: ['Photoshop', 'Illustrator', 'InDesign', 'UI/UX Design']
    },
    {
        id: '6',
        name: 'Anna Simonds',
        category: 'Web Developer',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5Z0nIrtNXwveC1zc5JnNoPXFsduS8ynKTWA&s',
        email: 'anna.simonds@example.com',
        phone: '+91-9567890123',
        company: 'Facebook',
        exp: '3 years',
        college: 'University of California, Berkeley',
        degree: 'B.S. in Computer Science',
        skills: ['HTML', 'CSS', 'JavaScript', 'React', 'Angular']
    }
];
