import React from 'react'
import './Testimonials.css';
import TestimonialCard from './TestimonialsCard/TestimonialCard';

function Testimonials() {
    return (
        <>
            <div className="testimonials-container">
                <h2>Students love using CodeQueue</h2>
                <div className="testimonials">
                    <TestimonialCard
                      text="Love the integrations with Calendar, Zoom and WhatsApp. Makes my life easier!"
                      authorName="Aishwarya Srinivasan"
                      authorTitle="LinkedIn Top Voice"
                      imageSrc="https://picsum.photos/200"
                      />
                    <TestimonialCard
                      text="The entire experience is just so seamless. My followers love it."
                      authorName="Joerg Storm"
                      authorTitle="300K on LinkedIn"
                      imageSrc="https://picsum.photos/200"
                      />
                    <TestimonialCard
                       text="CodeQueue is my go-to platform for scheduling 1:1 sessions and hosting webinars!"
                       authorName="Xinran Waibel"
                       authorTitle="Founder of Data Engineer Things"
                       imageSrc="https://picsum.photos/200"
                      />
                </div>
            </div>

        </>
    )
}

export default Testimonials