import React, { useEffect, useState } from "react";
import "./MentorsList.css";
import Select from "react-select";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";
import LanguageIcon from "@mui/icons-material/Language";
import image from "../../../assets/hero-img.png";
import WorkIcon from "@mui/icons-material/Work";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  domainOptions,
  companyOptions,
  skillsOptions,
  languageOptions,
} from "../../../Data/SelectOptions";

const MentorsList = () => {
  const location = useLocation();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Codequeue - Explore Mentors";
  }, []);

  const isProfileDetailsRoute = location.pathname.startsWith(
    "/mentors-list/details"
  );

  const mentorDetails = [
    {
      name: "Satyendra Kumar",
      location: "Mumbai, india",
      ratting: "2",
      lang: "English, Hindi",
      bio: "Main purpose is to help in interview preparation for top product based companies and right guidance so as to do smartwork and prepare in right direction.",
      eop: "8",
      company: "Microsoft",
      education: "Technocrats institute of technology",
      designation: "Software Developer",
      bookingCount: 5,
      skills: [
        "C++",
        "DSA",
        "SQL",
        "LLD",
        "HLD",
        "JavaScript",
        "React",
        "Mongo",
        "Express",
        "NodeJS",
      ],
    },
    {
      name: "Satyendra Kumar",
      location: "Mumbai, india",
      ratting: "2",
      lang: "English, Hindi",
      bio: "I want to be a guide to my mentees. Mentors are always required at every stage of life. Some people to get mentors at the right time. I want to be available for such mentees.",
      eop: "8",
      company: "Microsoft",
      education: "Technocrats institute of technology",
      designation: "Software Developer",
      bookingCount: 5,
      skills: [
        "Java",
        "DSA",
        "SQL",
        "LLD",
        "HLD",
        "JavaScript",
        "React",
        "Mongo",
        "Express",
        "NodeJS",
      ],
    },
    {
      name: "Satyendra Kumar",
      location: "Mumbai, india",
      ratting: "2",
      lang: "English, Hindi",
      bio: "Main purpose is to help in interview preparation for top product based companies and right guidance so as to do smartwork and prepare in right direction.",
      eop: "8",
      company: "Microsoft",
      education: "Technocrats institute of technology",
      designation: "Software Developer",
      bookingCount: 5,
      skills: [
        "Java",
        "DSA",
        "SQL",
        "LLD",
        "HLD",
        "JavaScript",
        "React",
        "Mongo",
        "Express",
        "NodeJS",
      ],
    },
    {
      name: "Satyendra Kumar",
      location: "Mumbai, india",
      ratting: "2",
      lang: "English, Hindi",
      bio: "Main purpose is to help in interview preparation for top product based companies and right guidance so as to do smartwork and prepare in right direction.",
      eop: "8",
      company: "Microsoft",
      education: "Technocrats institute of technology Bhopal, Madhya Pradesh",
      designation: "Software Developer",
      bookingCount: 5,
      skills: [
        "Java",
        "DSA",
        "SQL",
        "LLD",
        "HLD",
        "JavaScript",
        "React",
        "Mongo",
        "Express",
        "NodeJS",
      ],
    },
  ];

  const goToDetails = (index) => {
    navigate(`details/${index}`, {
      state: { mentor: mentorDetails[index] },
    });
  };

  

  return (
    <>
      {!isProfileDetailsRoute && (
        <div className="mentor-profiles flex">
          <div className="mentor-profiles-left">
            <div className="flex sort-by secondary-btn">
              <div className="sort-by-label">Sort By : </div>
              <select name="price" id="price">
                <option value="Recommended">Recommended</option>
                <option value="low-high">Pricing(Low to High)</option>
                <option value="high-low">Pricing(High to Low)</option>
              </select>
            </div>
            <h4 className="filter-by-text">Filter By</h4>
            <label className="filter-type">Domain</label>
            <Select
              options={domainOptions}
              placeholder="eg. Frontend, Backend, etc"
              isSearchable
              value={selectedDomain}
              onChange={(selectedOption) => setSelectedDomain(selectedOption)}
            />
            {/* <label className="filter-type">Offering mentorship for</label>
            <div className="offering-mentorship">
              <label className="offering-input flex">
                <input type="checkbox" name="offering" />
                <div>Freshers</div>
              </label>
              <label className="offering-input flex">
                <input type="checkbox" name="offering" />
                <div>Working Professionals</div>
              </label>
            </div> */}
            <label className="filter-type">Companies</label>
            <Select
              options={companyOptions}
              placeholder="eg. Amazon, Microsoft, etc"
              isSearchable
              value={selectedCompany}
              onChange={(selectedOption) => setSelectedCompany(selectedOption)}
            />

            <label className="filter-type">Skills</label>
            <Select
              options={skillsOptions}
              placeholder="eg. Java, DSA, sql, etc."
              isSearchable
              value={selectedSkills}
              isMulti
              onChange={(selectedOption) => setSelectedSkills(selectedOption)}
            />

            <label className="filter-type">Languages</label>
            <Select
              options={languageOptions}
              placeholder="eg. English, Hindi, Telgu, etc."
              isSearchable
              value={selectedLang}
              isMulti
              onChange={(selectedOption) => setSelectedLang(selectedOption)}
            />
          </div>

          <div className="mentor-profiles-right flex-col">
            {mentorDetails.map((item, index) => {
              return (
                <div
                  className="profile-shadow flex"
                  key={index}
                  onClick={() => goToDetails(index)}
                >
                  <div className="profile-shadow-left flex-col">
                    <div className="profile-card flex">
                      <div className="profile-card-left">
                        <img src={image} alt="img" />
                      </div>
                      <div className="profile-card-right flex-col">
                        <div className="flex-col card-content">
                          <h3>{item.name}</h3>
                          <div className="flex loca-rating-lang">
                            <div className="location">
                              <FmdGoodIcon className="location-icon" />
                              {item.location}
                            </div>
                            <div className="location">
                              <StarPurple500Icon className="location-icon" />{" "}
                              {item.ratting}+ Review
                            </div>
                            <div className="location">
                              <LanguageIcon className="location-icon" />
                              {item.lang}
                            </div>
                          </div>
                          <div className="mentor-bio">
                            {item.bio.slice(0, 100)}
                            <span>...Read More</span>
                          </div>
                        </div>{" "}
                        <div className="flex loca-rating-lang">
                          <div className="location">
                            <WorkIcon className="location-icon" />
                            {item.eop}+ Year of experience
                          </div>
                          <div className="location">
                            <MicrosoftIcon className="location-icon" />
                            {item.company}
                          </div>
                          <div className="location">
                            <PeopleAltIcon className="location-icon" />
                            {item.designation}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mentor-skills flex">
                      {item.skills.map((val, i) => {
                        return (
                          <div className="skills-key" key={i}>
                            {val}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-card-min-price flex-col">
                    <strong>Starting</strong>
                    <strong>Price at</strong>
                    <strong>₹500</strong>
                    <span className="primary-btn">Book Session</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export default MentorsList;
