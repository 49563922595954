import React from 'react'
import './TestimonialCard.css'
const TestimonialCard = ({ text, authorName, authorTitle, imageSrc }) => {
    return (
        <div className="testimonial">            
            <p>{text}</p>
            <div className="testimonial-author">
                <img src={imageSrc} alt={authorName} />
                <div className="author-info">
                    <h4>{authorName}</h4>
                    <p>{authorTitle}</p>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard