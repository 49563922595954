import React from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = ({ profiles }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        navigate('/dashboard/bookings/' + e.id, { state: e });
    };

    return (
        <div className="profile-grid">
            {profiles.map((profile, index) => (
                <div key={index} className="profile-item">
                    <img src={profile.image} alt={profile.name} />
                    <p>{profile.name}</p>
                    <h6>{profile.category}</h6>
                    <div className='px-4'>
                        <button className='btn-sm' onClick={() => handleClick(profile)}>Book Appointment</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Profile;
