import React, { useState } from 'react';
import './Faqs.css';
import QuizIcon from '@mui/icons-material/Quiz';
import Divider from '@mui/material/Divider';

const FAQ = () => {
  const quesAns = [
    {
      ques: 'What is codequeue?',
      ans: 'Codequeue is a platform designed to offer personalized, one-on-one mentorship for IT students. It focuses on several key services like Mentorship, Interview Preparation & Tips, Mock Interviews, Career Guidance, Resume Review and many more'
    },
    {
      ques: 'How can I connect?',
      ans: 'You can connect to industry experts by booking the one-on-one session as per your choice what you are looking for!'
    },
    {
      ques: 'How much will have to pay for sessions?',
      ans: 'It\'s depend on the sessions you are going to schedule each service has different price and it can vary as per the mentor you are choosing'
    },
    {
      ques: 'Is Codequeue really free?',
      ans: 'Yes, But for only limited serices are freely available, the session for that someone will drive you through the video call, you have to pay very minimal charges'
    }
  ];

  // State to track the currently opened question
  const [openIndex, setOpenIndex] = useState(null);

  const showAns = (index) => {
    setOpenIndex(prevIndex => prevIndex === index ? null : index);
  };

  return (
    <div className='faqs flex'>
      <div className="faqs-left">
        <div className="faqs-header">Frequently asked questions</div>
        <div className="faqs-para">Can't find the answer you are looking for?</div>
        <div className="reach-to-us">Reach out to us</div>
      </div>
      <div className="faqs-right">
        {
          quesAns.map((item, index) => (
            <div key={index}>
              <div onClick={() => showAns(index)} className={`faqs-ques flex ${openIndex === index ? 'change-icon-color' : ''}`}>
                <span>{item.ques}</span><span><QuizIcon /></span>
              </div>
              {openIndex === index && (
                <div className="faqs-ans">{item.ans}</div>
              )}
              <Divider />
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default FAQ;
