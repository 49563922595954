import './PrivacyAndPolicy.css'
import React, { useState } from 'react';

const PrivacyAndPolicy = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="privacy-policy">
      <h1>Privacy Policy : </h1>
      <p>Last Updated : <span className='policy-changed-date'>Sep 14, 2024</span></p>

      {/* Section 1: Information We Collect */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(1)} className="accordion-button">
          1. Information We Collect
        </button>
        {openSection === 1 && (
          <div className="accordion-content">
            <p>
              We collect both personal and non-personal information to improve our service and ensure a better experience for our users.
            </p>
            <p><strong>Personal Information:</strong></p>
            <p>
              This may include, but is not limited to, your name, email address, phone number, payment information, and any other information you provide during registration or through the platform. 
            </p>
            <p><strong>Non-Personal Information:</strong></p>
            <p>
              We also collect non-personal information, such as your browser type, the date and time of your visit, and pages viewed on our site.
            </p>
          </div>
        )}
      </div>

      {/* Section 2: How We Use Your Information */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(2)} className="accordion-button">
          2. How We Use Your Information
        </button>
        {openSection === 2 && (
          <div className="accordion-content">
            <p>We use the information we collect for several purposes, including:</p>
            <ul>
              <li>To provide, operate, and maintain our website</li>
              <li>To improve, personalize, and expand our website</li>
              <li>To communicate with you, either directly or through one of our partners</li>
              <li>To send you emails</li>
              <li>To prevent fraud and ensure security</li>
            </ul>
          </div>
        )}
      </div>

      {/* Section 3: Information Sharing */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(3)} className="accordion-button">
          3. Information Sharing
        </button>
        {openSection === 3 && (
          <div className="accordion-content">
            <p>
              We may share your information with third parties in certain situations:
            </p>
            <ul>
              <li>With service providers to assist us in running our platform</li>
              <li>In response to a legal request or to comply with applicable laws</li>
              <li>To protect the rights and safety of our users and the public</li>
            </ul>
          </div>
        )}
      </div>

      {/* Section 4: Data Security */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(4)} className="accordion-button">
          4. Data Security
        </button>
        {openSection === 4 && (
          <div className="accordion-content">
            <p>
              We take the security of your data seriously and implement various technical and organizational measures to protect it.
            </p>
            <p>
              However, no method of transmission over the internet is 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
            </p>
          </div>
        )}
      </div>

      {/* Section 5: Your Data Rights */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(5)} className="accordion-button">
          5. Your Data Rights
        </button>
        {openSection === 5 && (
          <div className="accordion-content">
            <p>
              You have the right to access, correct, or delete the personal information we hold about you. To exercise these rights, please contact us at <strong>support@codequeue.in</strong>.
            </p>
          </div>
        )}
      </div>

      {/* Section 6: Changes to This Privacy Policy */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(6)} className="accordion-button">
          6. Changes to This Privacy Policy
        </button>
        {openSection === 6 && (
          <div className="accordion-content">
            <p>
              We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
            </p>
            <p>
              This policy was last updated on <span className='policy-changed-date'>Sep 14 2024</span>
            </p>
          </div>
        )}
      </div>

      {/* Section 7: Contact Us */}
      <div className="accordion-section">
        <button onClick={() => toggleSection(7)} className="accordion-button">
          7. Contact Us
        </button>
        {openSection === 7 && (
          <div className="accordion-content">
            <p>
              If you have any questions or suggestions about our privacy policy, do not hesitate to contact us at <strong>support@codequeue.in</strong>.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
