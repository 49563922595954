import React, { useState } from 'react';
import './booking.css';
import { users } from '../../../Data/Users';
import Filter from './Filter';
import Profile from './Profile';

const Booking = () => {
  const [bookings, setBookings] = useState([]);


  const [filter, setFilter] = useState("");
  const [profiles, setProfiles] = useState(users);

  const filteredProfiles = profiles.filter(profile =>
      profile.name.toLowerCase().includes(filter.toLowerCase()) ||
      profile.category.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <div style={{ display: 'flex', }}>
        <div style={{ flex: '1 1 30%',  }}>
          <Filter onFilterChange={setFilter} />
        </div>
        <div style={{ flex: '1 1 70%', }}>
          <Profile profiles={filteredProfiles} />
        </div>
      </div>
    </>
  );
};

export default Booking;
