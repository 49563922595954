import React, { useEffect } from "react";
import "./MentorDetails.css";
import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import BookmarkBorderTwoToneIcon from "@mui/icons-material/BookmarkBorderTwoTone";
import imge from "../../../assets/hero-img.png";
import { useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";
import SchoolIcon from "@mui/icons-material/School";
import LanguageIcon from "@mui/icons-material/Language";
import PublicIcon from "@mui/icons-material/Public";
import MentorPricing from "../MentorPricing/MentorPricing";
import RecentReview from "../RecentReview/RecentReview";
import MentorsTechnicalSkills from "../MentorsTechnicalSkills/MentorsTechnicalSkills";

const MentorDetails = () => {
  const location = useLocation();
  const { mentor } = location.state;
  useEffect(() => {
    document.title = "Codequeue - Profile";
  }, []);
  return (
    <div className="mentor-details flex-col">
      <div className="mentor-details-profile card-shadow">
        <div className="upper">
          <div className="mentor-details-image flex">
            <img src={imge} alt="Profile of the mentor" />
          </div>
        </div>
        <div className="middle flex">
          <span>
            <FavoriteBorderTwoToneIcon />
          </span>
          <span>
            <BookmarkBorderTwoToneIcon />
          </span>
          <span>View Pricing</span>
        </div>
        <div className="lower flex">
          <div className="lower-left">
            <h4>{mentor.name}</h4>
            <div className="flex location-rating-lang">
              <div className="location">
                <PeopleAltIcon className="location-icon" />
                {mentor.designation}
              </div>
              <div className="location">
                <StarPurple500Icon className="location-icon" /> {mentor.ratting}
                + Rating
              </div>
            </div>
            <div className="mentor-detail-bio">{mentor.bio}</div>
            <div className="flex location-rating-lang">
              <div className="location">
                <FmdGoodIcon className="location-icon" />
                {mentor.location}
              </div>
              <div className="location">
                <LanguageIcon className="location-icon" />
                {mentor.lang}
              </div>
              <div className="location">
                <PublicIcon className="location-icon" />
                {mentor.bookingCount}+ Bookings
              </div>
            </div>
          </div>
          <div className="lower-right flex-col">
            <div className="location">
              <WorkIcon className="location-icon" />
              {mentor.eop}+ Year of experience
            </div>
            <div className="location">
              <MicrosoftIcon className="location-icon" />
              {mentor.company}
            </div>
            <div className="location">
              <SchoolIcon className="location-icon" />
              {mentor.education}
            </div>
          </div>
        </div>
      </div>
      <div className="mentor-details-pricing card-shadow">
        <MentorPricing />
      </div>
      <div className="mentor-details-skills card-shadow">
        <MentorsTechnicalSkills mentor={mentor} />
      </div>
      <div className="mentor-details-available-slot"></div>
      <div className="mentor-details-testimonials card-shadow">
        <RecentReview />
      </div>
    </div>
  );
};

export default MentorDetails;
