import React, { useEffect, useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./Sessions.css";
import AddMoreSessions from "./AddMoreSessions/AddMoreSessions";
import UpdateExistingSessions from "./UpdateExistingSessions/UpdateExistingSessions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { handleError } from "../../../Utils";
import axios from "axios";
import DeleteSession from "./DeleteSessions/DeleteSession";

const Sessions = () => {
  const [isAddSessionsVisible, setIsAddSessionsVisible] = useState(false);
  const [isUpdateSessionsVisible, setIsUpdateSessionsVisible] = useState(false);
  const [isDeleteSessionVisible, setIsDeleteSessionVisible] = useState(false);
  const email = localStorage.getItem("userEmail");
  const [sessionsDetails, setSessionsDetails] = useState([]);
  const [index, setIndex] = useState(null);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [amount, setAmount] = useState("");
  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) return; // Exit if already called
    calledOnce.current = true;
    const getSessionDetails = async () => {
      try {
        const response = await axios.post(
          "http://localhost:4000/api/v1/new-session",
          { email }
        );
        setSessionsDetails(response.data.sessions);
      } catch (error) {
        return handleError(error.response.data.message);
      }
    };

    getSessionDetails();
  }, [email]);
  const showUpdatePopUp = (index, title, duration, amount) => {
    setIndex(index);
    setTitle(title);
    setDuration(duration);
    setAmount(amount);
    setIsUpdateSessionsVisible(!isUpdateSessionsVisible);
  };
  const showDeletePopUp = (index)=>{
    setIndex(index);
    setIsDeleteSessionVisible(!isDeleteSessionVisible);
  }
  return (
    <div className="mentor-pricing flex">
      {sessionsDetails.map((item, index) => {
        return (
          <div className="mentor-pricing-card flex-col" key={index}>
            <div className="mentor-pricing-type delete-sessions flex">
              <span>{item.title}</span>{" "}
              <span className="delete-icon">
                <DeleteIcon onClick={()=>showDeletePopUp(index)} />
                <EditIcon
                  className="edit-delete"
                  onClick={() =>
                    showUpdatePopUp(index, item.title, item.duration, item.amount)
                  }
                />
              </span>
            </div>
            <div className="mentor-pricing-price flex">
              <div className="flex">
                <CalendarMonthIcon className="icon" />
                <div className="video-meeting">
                  <div className="time">{item.duration} mins</div>
                  <div>Video Meeting</div>
                </div>
              </div>
              <div className="price-value">
                ₹{item.amount}
                <ArrowForwardIcon />
              </div>
            </div>
          </div>
        );
      })}
      <div
        className="mentor-pricing-card add-more-btn"
        onClick={setIsAddSessionsVisible}
      >
        <span>Add More</span>
        <span>
          <AddCircleIcon />
        </span>
      </div>
      <AddMoreSessions
        isVisible={isAddSessionsVisible}
        setIsVisible={setIsAddSessionsVisible}
      />
      <UpdateExistingSessions
        isVisible={isUpdateSessionsVisible}
        setIsVisible={setIsUpdateSessionsVisible}
        index={index}
        title={title}
        setTitle={setTitle}
        duration={duration}
        setDuration={setDuration}
        amount={amount}
        setAmount={setAmount}
      />
      {isDeleteSessionVisible && (
        <DeleteSession
          email={email}
          index={index}
          isVisible={isDeleteSessionVisible}
          setIsVisible={setIsDeleteSessionVisible}
        />
      )}
    </div>
  );
};

export default Sessions;
