import React from 'react';

const Filter = ({ onFilterChange }) => {
    return (
        <div style={{ paddingRight:"15px" }}>
            <h3>Filters</h3>
            <label>
                Search:
                <input type="text" onChange={(e) => onFilterChange(e.target.value)} placeholder="Search by name"/>
            </label>
            <br />
            <label>
                Category:
                <select onChange={(e) => onFilterChange(e.target.value)}>
                    <option value="">All</option>
                    <option value="SDE-I">SDE-I</option>
                    <option value="SDE-II">SDE-II</option>
                    <option value="Software Engineer">Software Engineer</option>
                    <option value="Graphic Designer">Graphic Designer</option>
                    <option value="Web Developer">Web Developer</option>
                </select>
            </label>
            <label>
                Company:
                <select onChange={(e) => onFilterChange(e.target.value)}>
                    <option value="">All</option>
                    <option value="Capgemini">Capgemini</option>
                    <option value="Infosys">Infosys</option>
                    <option value="Wipro">TCS</option>
                </select>
            </label>

            <label>
                Experience:
                <select onChange={(e) => onFilterChange(e.target.value)}>
                    <option value="">All</option>
                    <option value="1">1+</option>
                    <option value="2">2+</option>
                    <option value="3">2+</option>
                </select>
            </label>
        </div>
    );
};

export default Filter;
