import React from "react";
import "./RecentReview.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const RecentReview = () => {
  const reviewDetails = [
    {
      review:
        "I hope this message finds you well. I wanted to take a moment to express my heartfelt gratitude for the guidance and support you've provided meduring our time together. Your mentorship has been invaluable to me, and I truly appreciate the time and effort you've invested in helping me grow both personally and professionally. Your insights, advice, and encouragement have made a significant difference in my journey, and I am incredibly grateful for your wisdom and expertise. I am ready to take up this mentor ship",
      name: "Satyendra Kumar",
      date: 6,
    },
    {
      review:
        "I hope this message finds you well. I wanted to take a moment to express my heartfelt gratitude for the guidance and support you've provided meduring our time together. Your mentorship has been invaluable to me, and I truly appreciate the time and effort you've invested in helping me grow both personally and professionally. Your insights, advice, and encouragement have made a significant difference in my journey, and I am incredibly grateful for your wisdom and expertise. I am ready to take up this mentor ship",
      name: "Sonali Patle",
      date: 6,
    },
    {
      review:
        "I hope this message finds you well. I wanted to take a moment to express my heartfelt gratitude for the guidance and support you've provided meduring our time together. Your mentorship has been invaluable to me, and I truly appreciate the time and effort you've invested in helping me grow both personally and professionally. Your insights, advice, and encouragement have made a significant difference in my journey, and I am incredibly grateful for your wisdom and expertise. I am ready to take up this mentor ship",
      name: "Nirala Kumar",
      date: 6,
    },
  ];
  return (
    <div className="recent-review">
      <div className="recent-review-top">
        <div>Reviews & Testimonials</div>
      </div>

      {reviewDetails.map((item, index) => {
        return (
          <div key={index}>
            <div className="content-seprator"></div>
            <div className="recent-review-middle">
              {item.review}
            </div>
            <div className="recent-review-bottom flex">
              <div className="candidate-profile flex">
                <div className="candidate-profile-top flex">
                  {item.name.charAt(0)}
                </div>
                <div>{item.name}</div>
              </div>
              <div className="comment-date">
                {item.date} months ago
              </div>
            </div>
          </div>
        );
      })}
      <div className="seprator flex">
              <div className="left-seprator"></div>
              <div className="show-all-review flex">
                Show All
                <KeyboardArrowDownIcon />
              </div>
              <div className="left-seprator"></div>
            </div>
    </div>
  );
};

export default RecentReview;
