import React, { useState } from "react";
import { handleError, handleSuccess } from "../../../../Utils";
import axios from "axios";
import "./UpdateExistingSessions.css";
const UpdateExistingSessions = ({
  isVisible,
  setIsVisible,
  index,
  title,
  setTitle,
  duration,
  setDuration,
  amount,
  setAmount,
}) => {
  const email = localStorage.getItem("userEmail");
  const handleSubmit = async () => {
    if (!title) {
      return handleError("Title cannot be empty");
    }
    if (!duration) {
      return handleError("Duration cannot be empty");
    }
    if (!amount) {
      return handleError("Amount cannot be empty");
    }
    try {
      console.log({ email, title, duration, amount, index });
      const response = await axios.post(
        "http://localhost:4000/api/v1/new-session",
        { email, title, duration, amount, index }
      );
      setTitle("");
      setDuration("");
      setAmount("");
      setIsVisible(false);
      window.location.reload();
      return handleSuccess(response.data.message);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  return (
    <>
      {isVisible && (
        <div className="update-session-popup-overlay">
          <div className="update-session-popup-content">
            <h2>Update Sessions</h2>
            <form>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Name of Session"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Duration (in minutes)</label>
                <input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Amount (₹)</label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="button-group flex">
                <a
                  type="button"
                  onClick={() => setIsVisible(false)}
                  className="secondary-btn"
                >
                  Close
                </a>
                <a type="button" onClick={handleSubmit} className="primary-btn">
                  Submit
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateExistingSessions;
