import { Link } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./MentorPricing.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AvailableSlot from "../AvailableSlot/AvailableSlot";
import { useState } from "react";

const MentorPricing = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sessionType = [
    { type: "Mock Interview", duration: 30, price: 200 },
    { type: "Interview prep & tips", duration: 30, price: 500 },
    { type: "Career guidance", duration: 15, price: 200 },
    { type: "1:1 Mentorship", duration: 60, price: 400 },
    { type: "Resume Review", duration: 30, price: 200 },
    { type: "Quick call", duration: 30, price: 200 },
  ];

  return (
    <>
    <h2 className="book-your-session">Book Session Now</h2>
    <div className="mentor-pricing flex">
      {sessionType.map((item, index) => {
        return (
          <div className="mentor-pricing-card flex-col" key={index} onClick={setIsVisible}>
            <div className="mentor-pricing-type">{item.type}</div>
            <div className="mentor-pricing-price flex">
              <div className="flex">
                <CalendarMonthIcon className="icon" />
                <div className="video-meeting">
                  <div className="time">{item.duration} mins</div>
                  <div>Video Meeting</div>
                </div>
              </div>
              <div className="price-value">
                ₹{item.price}<ArrowForwardIcon />
              </div>
            </div>
          </div>
        );
      })}
    </div>
    <AvailableSlot isVisible={isVisible} setIsVisible={setIsVisible}/>
    </>
  );
};

export default MentorPricing;
