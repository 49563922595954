import React from "react";
import "./MentorsTechnicalSkills.css";

const MentorsTechnicalSkills = ({ mentor }) => {
  return (
    <div className="mentors-technical-skills">
      <div>
        <h4>Technical Skills</h4>
        <div className="flex">
          {mentor.skills.map((skill, index) => (
            <div key={index} className="tech-skills">
              {skill}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MentorsTechnicalSkills;
