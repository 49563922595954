import React, { useEffect, useState } from "react";
import "./MentorOnboarding.css";
import { handleError, handleSuccess } from "../../../Utils";
import { NavLink, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Select from "react-select";
import { companyOptions } from "../../../Data/SelectOptions";
import axios from "axios";

const MentorOnboarding = () => {
  // State variables to hold form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [graduationPassingYear, setGraduationPassingYear] = useState(null);
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [isAcceptedPolicy, setIsAcceptedPolicy] = useState(false);
  const [isVisibleEP, setIsVisibleEP] = useState(true);
  const [isVisibleNCCGY, setIsVisibleNCCGY] = useState(false);
  const [isVisiblePCP, setIsVisiblePCP] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [mailOtp, setMailOtp] = useState("");
  const [numOtp, setNumOtp] = useState("");
  const [isEditableNum, setIsEditableNum] = useState(true);
  const [isEditableEmail, setIsEditableEmail] = useState(true);
  const [isNumOtpInputVisible, setIsNumOtpInputVisible] = useState(false);
  const [isEmailOtpInputVisible, setIsEmailOtpInputVisible] = useState(false);

  useEffect(() => {
    document.title = "Codequeue - Mentor Onboarding";
  }, []);

  // send otp on email
  const emailOtpSend = async () => {
    try {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return handleError("Please Enter Valid Email.");
      }
      const response = await axios.post(
        "http://localhost:4000/api/v1/send-email-otp",
        { email }
      );
      setTimeout(() => {
        setIsEmailOtpInputVisible(true);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };
  // verify email otp
  const emailOtpVerify = async () => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/v1/verify-email-otp",
        { email, mailOtp }
      );
      setMailOtp("");
      setTimeout(() => {
        setIsEmailOtpInputVisible(false);
        setIsEditableEmail(false);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };
  // send otp on number
  const numOtpSend = async () => {
    try {
      const phoneRegex = /^\d{10}$/; // Ensures exactly 10 digits
      if (!phoneRegex.test(phone)) {
        return handleError("Phone number must be exactly 10 digits.");
      }
      const response = await axios.post(
        "http://localhost:4000/api/v1/send-otp",
        { phone }
      );
      setTimeout(() => {
        setIsNumOtpInputVisible(true);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };
  // verify num otp
  const numOtpVerify = async () => {
    try {
      const otp = numOtp;
      const response = await axios.post(
        "http://localhost:4000/api/v1/verify-otp",
        { phone, otp }
      );
      setNumOtp("");
      setTimeout(() => {
        setIsNumOtpInputVisible(false);
        setIsEditableNum(false);
        return handleSuccess(response.data.message);
      }, 800);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  const toggleBackPage = (index) => {
    if (index === 2) {
      setIsVisiblePCP(false);
      setIsVisibleNCCGY(true);
      setCurrentIndex(currentIndex - 1);
    }
    if (index === 1) {
      setIsVisibleNCCGY(false);
      setIsVisibleEP(true);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const toggleNextPage = async (index) => {
    if (index === 0) {
      setIsVisibleEP(false);
      setIsVisibleNCCGY(true);
      setCurrentIndex(currentIndex + 1);
    }
    if (index === 1) {
      setIsVisibleNCCGY(false);
      setIsVisiblePCP(true);
      setCurrentIndex(currentIndex + 1);
    }
    if (index === 2 && isAcceptedPolicy) {
      try {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          return handleError("Please enter a valid email address");
        }
        if (!/^\d{10}$/.test(phone)) {
          return handleError("Phone number must be exactly 10 digits");
        }
        if (name.length < 3) {
          return handleError("Name must be at least 3 characters long");
        }
        if (!selectedCompany) {
          return handleError("Please enter current company name");
        }
        if (!graduationPassingYear) {
          return handleError("Passing year is required");
        }
        if (password.length < 6) {
          return handleError("Password must be at least 6 characters");
        }

        if (password !== cpassword) {
          return handleError("Passwords do not match");
        }

        const response = await axios.post(
          "http://localhost:4000/api/v1/mentor-signup",
          {
            name,
            email,
            phone,
            currentCompany: selectedCompany.value,
            graduationPassingYear: graduationPassingYear.$y,
            password,
            isAcceptedPolicy,
          }
        );
        handleSuccess(response.data.message);
        setEmail("");
        setPhone("");
        setName("");
        setSelectedCompany("");
        setGraduationPassingYear(null);
        setPassword("");
        setcPassword("");
        setIsAcceptedPolicy(false);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
        // console.log("Submitted");
        // console.log(name);
        // console.log(email);
        // console.log(phone);
        // console.log(selectedCompany.value);
        // console.log(graduationPassingYear.$y);
        // console.log(password);
        // console.log(cpassword);
        // console.log(isAcceptedPolicy);
      } catch (error) {
        return handleError(error.response.data.message);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAcceptedPolicy(e.target.checked);
  };

  return (
    <div className="mentor-onboarding bg-clr flex">
      <div className="m-onboarding">
        <h3 className="mentor-header-text">Join or Sign in as a Mentor</h3>
        <form className="mentor-form flex-col">
          <div
            className={`mentor-form-group flex-col ${
              isVisibleEP ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="email">Email:</label>
            <div className="flex">
              <input
                readOnly={!isEditableEmail}
                type="email"
                id="email"
                value={email}
                placeholder="Your email address"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <span onClick={emailOtpSend} className="otp-send flex">
                Send
              </span>
            </div>
            <div
              className={`otp-verify flex ${
                isEmailOtpInputVisible ? "" : "otp-toggle"
              }`}
            >
              <input
                type="text"
                placeholder="Enter OTP"
                value={mailOtp}
                onChange={(e) => setMailOtp(e.target.value)}
              />
              <span onClick={emailOtpVerify}>Verify</span>
            </div>
          </div>

          <div
            className={`mentor-form-group flex-col ${
              isVisibleEP ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="phone">Phone:</label>
            <div className="flex">
              <input
                readOnly={!isEditableNum}
                type="tel"
                id="phone"
                placeholder="Your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <span onClick={numOtpSend} className="otp-send flex">
                Send
              </span>
            </div>
            <div
              className={`otp-verify flex ${
                isNumOtpInputVisible ? "" : "otp-toggle"
              }`}
            >
              <input
                type="text"
                placeholder="Enter OTP"
                value={numOtp}
                onChange={(e) => setNumOtp(e.target.value)}
              />
              <span onClick={numOtpVerify}>Verify</span>
            </div>
          </div>

          <div
            className={`mentor-form-group flex-col ${
              isVisibleNCCGY ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              placeholder="Your name"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div
            className={`mentor-form-group flex-col ${
              isVisibleNCCGY ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="currentCompany">Current Company:</label>
            <Select
              options={companyOptions}
              isSearchable
              isClearable
              className="current-company-options"
              value={selectedCompany}
              onChange={(selectedOption) => setSelectedCompany(selectedOption)}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                }),
              }}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>

          <div
            className={`mentor-form-group flex-col ${
              isVisibleNCCGY ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="graduationYear">Graduation(passing year) :</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="current-company-options"
                value={graduationPassingYear}
                onChange={(date) => setGraduationPassingYear(date)}
                format="YYYY"
                views={["year"]}
                minDate={dayjs("1900-01-01")} // Minimum year is 1900
                maxDate={dayjs().endOf("year")} // Maximum year is the current year
              />
            </LocalizationProvider>
          </div>

          <div
            className={`mentor-form-group flex-col ${
              isVisiblePCP ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <div
            className={`mentor-form-group flex-col ${
              isVisiblePCP ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="password">Confirm Password:</label>
            <input
              type="password"
              id="cpassword"
              value={cpassword}
              placeholder="Confirm Password"
              onChange={(e) => setcPassword(e.target.value)}
              required
            />
          </div>

          <div
            className={`mentor-term-conditions flex ${
              isVisiblePCP ? "" : "hide-elem"
            }`}
          >
            <label htmlFor="terms">
              <span>
                <input
                  type="checkbox"
                  id="terms"
                  required
                  className="check-box"
                  checked={isAcceptedPolicy}
                  onChange={handleCheckboxChange}
                />
              </span>
              I agree to the{" "}
              <a href="/terms-and-conditions" target="_blank">
                T&C
              </a>{" "}
              and{" "}
              <NavLink to="/privacy-and-policy" target="_blank">
                Privacy & Policy
              </NavLink>
            </label>
          </div>

          <div
            className={`flex ${
              isVisibleEP ? "mentor-next-btn1" : "mentor-next-btn"
            }`}
          >
            <a
              className={`secondary-btn ${isVisibleEP ? "hide-elem" : ""}`}
              onClick={() => toggleBackPage(currentIndex)}
            >
              Back
            </a>
            <a
              className={`primary-btn ${
                currentIndex === 2 && !isAcceptedPolicy ? "submit-btn-bg" : ""
              }`}
              onClick={() => toggleNextPage(currentIndex)}
            >
              {currentIndex === 2 ? "Submit" : "Next"}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MentorOnboarding;
