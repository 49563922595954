import React, { useState } from 'react'
import './Sidebar.css'
import HomeIcon from '@mui/icons-material/Home';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import PaymentIcon from '@mui/icons-material/Payment';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { NavLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Profile from '../Profile/Profile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeSlots from '../TimeSlots/TimeSlots';

function Sidebar() {
  const [isVisibleProfile, setIsVisibleProfile] = useState(false);
  const [isVisibleTimeSlots, setIsVisibleTimeSlots] = useState(false);
  const role = localStorage.getItem("role");
  return (
    <aside className="sidebar">
      <nav>
        <ul>
          <NavLink to="home"><li><HomeIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Home</span></li></NavLink>
          <li onClick={setIsVisibleProfile} className={(role==='user')?"dis-none":""}><AccountCircleIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Profile</span></li>
          <li><AccountBalanceIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Account</span></li>
          <NavLink to="sessions" className={(role==='user')?"dis-none":""}><li><MenuBookIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Sessions</span></li></NavLink>
          <li onClick={setIsVisibleTimeSlots} className={(role==='user')?"dis-none":""}><AccessTimeIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Time Slots</span></li>
          <NavLink to="bookings"><li><WifiCalling3Icon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Bookings</span></li></NavLink>
          <NavLink to="prioritydm"><li><VoiceChatIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Priority DM</span></li></NavLink>
          <NavLink to="payments"><li><PaymentIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Payments</span></li></NavLink>
          <li><CardGiftcardIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>Rewards</span></li>
          <li className={(role==='user')?"dis-none":""}><NotificationsNoneIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span className='sidebar-span'>What's New</span></li>
        </ul>
      </nav>
      <Profile isVisible={isVisibleProfile} setIsVisible={setIsVisibleProfile} />
      <TimeSlots isVisible={isVisibleTimeSlots} setIsVisible={setIsVisibleTimeSlots}/>

    </aside>
  )
}

export default Sidebar