import React from 'react';
import './payments.css'



// Sample function to handle removing a card
const handleRemoveCard = (index) => {
    // Here you can implement the logic to remove a card, such as updating state or calling an API
    console.log(`Remove card at index: ${index}`);
};

const Payments = () => {


    const paymentHistory = [
        { date: '2024-08-01', amount: 49.99, method: 'Visa **** 1234', status: 'Completed' },
        { date: '2024-07-15', amount: 19.99, method: 'Mastercard **** 5678', status: 'Completed' },
        { date: '2024-06-20', amount: 29.99, method: 'PayPal', status: 'Completed' },
    ];

    const savedCards = [
        { cardType: 'Visa', lastFour: '1234', expiry: '08/25' },
        { cardType: 'Mastercard', lastFour: '5678', expiry: '12/24' },
    ];
    return (
        <div className="payments-container">
            <h2>Payment History</h2>
            <div className="payment-history">
                {paymentHistory.length === 0 ? (
                    <p>No payment history available.</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Payment Method</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentHistory.map((payment, index) => (
                                <tr key={index}>
                                    <td>{payment.date}</td>
                                    <td>{`$${payment.amount}`}</td>
                                    <td>{payment.method}</td>
                                    <td>{payment.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>

    );
};


export default Payments;