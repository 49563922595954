import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./TimeSlots.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteSlotsDate from "./DeleteSlotsDate";
import ChooseTime from "./ChooseTime";
import axios from "axios";
import { handleError, handleSuccess } from "../../../Utils";

const TimeSlots = ({ isVisible, setIsVisible }) => {
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [slotDate, setSlotDate] = useState([]);
  const [isConfirmationWindowVisible, setIsConfirmationWindowVisible] =
    useState(false);
  const [isTimePopupVisible, setIsTimePopupVisible] = useState(false);
  const email = localStorage.getItem("userEmail");
  const role = localStorage.getItem("role");
  const hasFetched = useRef(false);

  useEffect(() => {
    const getSlotsDetails = async () => {
      try {
        if (role === "mentor" && !hasFetched.current) {
          const response = await axios.post(
            "http://localhost:4000/api/v1/available-slots",
            { email }
          );
          setSlotDate(response.data.data);
          hasFetched.current = true;
        }
      } catch (error) {
        return handleError(error.response.data.message);
      }
    };

    getSlotsDetails();
  }, [email]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Format date components
    const dayName = date.format("dddd").substring(0, 3).toUpperCase();
    const day = date.format("DD");
    const month = date.format("MMMM").substring(0, 3);
    const newDateEntry = {
      day: dayName,
      date: `${day} ${month}`,
      slot: 0,
      time: [],
    };

    setIsDatePickerOpen(false);

    setSlotDate((prevSlotDate) => {
      const isDuplicate = prevSlotDate.some(
        (slot) =>
          slot.date === newDateEntry.date && slot.day === newDateEntry.day
      );

      if (!isDuplicate) {
        // Add new date entry and sort the array by month and day
        const updatedSlotDate = [...prevSlotDate, newDateEntry].sort((a, b) => {
          const [dayA, monthA] = a.date.split(" ");
          const [dayB, monthB] = b.date.split(" ");

          // Compare months first, then days
          if (monthA !== monthB) {
            return monthA.localeCompare(monthB);
          }
          return parseInt(dayA) - parseInt(dayB);
        });

        return updatedSlotDate;
      }
      return prevSlotDate;
    });
  };
  const handleRemoveTime = (index) => {
    setSlotDate((prevSlotDate) => {
      const updatedSlotDate = [...prevSlotDate];
      const updatedTimeArray = [...updatedSlotDate[selectedDateIndex].time];
      updatedTimeArray.splice(index, 1);
      updatedSlotDate[selectedDateIndex] = {
        ...updatedSlotDate[selectedDateIndex],
        time: updatedTimeArray,
        slot: updatedTimeArray.length,
      };
      return updatedSlotDate;
    });
  };

  const handleUpdateSlots = async () => {
    try {
      const response = await axios.post(
        "http://localhost:4000/api/v1/available-slots",
        { email, slots: slotDate }
      );
      return handleSuccess(response.data.message);
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  return (
    <>
      {isVisible && (
        <div className="book-time-slot-overlay">
          <div className="book-time-slot-popup flex-col">
            <div
              className="time-slot-close-icon"
              onClick={() => setIsVisible(false)}
            >
              <CloseIcon />{" "}
            </div>
            <div className="time-slot-available-text">Available Dates</div>
            <div className="time-slot-available-slots flex">
              {slotDate.map((item, index) => {
                return (
                  <div className="flex-col" key={index}>
                    <div
                      onClick={() => setSelectedDateIndex(index)}
                      className={`time-slot-available-dates flex-col ${
                        selectedDateIndex === index
                          ? "time-slot-is-date-selected"
                          : ""
                      }`}
                    >
                      <div className="day">
                        <span>{item.day}</span>{" "}
                        <span className="remove-date-icon">
                          <RemoveCircleOutlineIcon
                            onClick={setIsConfirmationWindowVisible}
                            style={{ fontSize: "16px" }}
                          />
                        </span>
                      </div>
                      <div className="date">{item.date}</div>
                      <div className="slots">{item.slot} slots</div>
                    </div>
                  </div>
                );
              })}
              <div className="add-more-slots">
                <label
                  htmlFor="pic-a-date"
                  onClick={() => setIsDatePickerOpen(true)}
                >
                  <AddCircleIcon />
                </label>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {isDatePickerOpen && (
                    <DatePicker
                      value={selectedDate}
                      onChange={(selectedDate) =>
                        handleDateChange(selectedDate)
                      }
                      format="DD MMMM YYYY"
                      views={["month", "day"]}
                      open
                      onClose={() => setIsDatePickerOpen(false)}
                    />
                  )}
                </LocalizationProvider>
              </div>
            </div>
            <div className="time-slot-divider"></div>
            <div className="time-slot-available-date flex">
              <div className="">Available Slots</div>
              <div className="scroll-icon"></div>
            </div>
            <div className="time-slot-available-slots flex">
              {slotDate[selectedDateIndex]?.time.map((time, index) => {
                return (
                  <div style={{ textAlign: "end" }} key={index}>
                    <RemoveCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveTime(index)}
                    />
                    <div
                      className="time-slot-available-dates flex-col"
                      key={index}
                    >
                      <div className="time-slot-available-time">
                        {time.value}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="add-more-slots">
                <AddCircleIcon onClick={setIsTimePopupVisible} />
              </div>
              {isTimePopupVisible && (
                <ChooseTime
                  isVisible={isTimePopupVisible}
                  setIsVisible={setIsTimePopupVisible}
                  setSlotDate={setSlotDate}
                  index={selectedDateIndex}
                />
              )}
            </div>
            <div className="primary-btn book-now" onClick={handleUpdateSlots}>
              Update
            </div>
          </div>
        </div>
      )}
      {isConfirmationWindowVisible && (
        <DeleteSlotsDate
          isVisible={isConfirmationWindowVisible}
          setIsVisible={setIsConfirmationWindowVisible}
          index={selectedDateIndex}
          slotDate={slotDate}
          setSlotDate={setSlotDate}
        />
      )}
    </>
  );
};

export default TimeSlots;
