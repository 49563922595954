import React from "react";
import "./RefundPolicy.css"; // Add this CSS file for styling

const RefundPolicy = () => {
  return (
    <div className="refund-policy">
      <h3>Refund Policy</h3>

      <div>
        Thank you for booking the sessions. We ensure that our users have a
        rewarding experience while they discover, assess, and purchase our
        services.
      </div>
      <br />
      <div>
        As with any online purchase experience, there are terms and conditions
        that govern the Refund Policy. When you buy book a sessions on CodeQueue Technologies Pvt Ltd, you agree to our Privacy Policy,
        Terms of Use, and refund policy.
      </div>
      <br />

      {/* Price Range Section */}
      <strong>Price Range</strong>
      <p>
        At CODEQUEUE PRIVATE LIMITED, we have customized pricing according
        to the services rendered by us. The details are provided to you
        beforehand according to the effort, efficiency, and the output of the
        service. Typically, the range of transactions on our website varies from
        INR 200/- to 5000/-.
      </p>

      {/* Schedule of payment Section */}
      <strong>Schedule of Payment</strong>
      <p>
        Some of our services can be utilized for fixed durations. In such cases,
        it is clearly mentioned within the description of these services. The
        period of usage in these case until the services is not completed.
      </p>

      {/* Price Matching Section */}
      <strong>Price Matching</strong>
      <p>
        At CODEQUEUE PRIVATE LIMITED, we are committed to offering you the
        best possible prices.
      </p>

      {/* Pricing Errors Section */}
      <strong>Pricing Errors</strong>
      <p>
        We work hard to ensure the accuracy of pricing. Despite our efforts,
        pricing errors may still occur. If a services price is higher than the
        price displayed, we will cancel your order of that item and notify you
        of the cancellation.
      </p>

      {/* Refunds Policy Section */}
      <strong>Refunds Policy</strong>
      <strong>Refunds: For Online Courses</strong>
      <p>
        There will be a 10% refund charge, and you will get 90% of your total
        paid amount back into your account in case of a refund, only if the
        request is raised before 24hrs of meeting start time. In case of cancellation of meeting within 24hr, fees are not refundable under any circumstances. Any refund
        requests raised within the meeting start date/time will not be considered.
      </p>
      <strong>
        To claim the refund, kindly follow the
        procedure below:
      </strong>
      <ol>
        <li>
          Drop an email with a subject “Session booking refund | Registered email
          id”. (Please do not forget to provide the registered email id in the
          subject)
        </li>
        <li>
          Provide a valid reason for the refund. (For our internal purpose only)
        </li>
        <li>
          Ensure that the email is received within 20hrs of the meeting start
          time.
        </li>
      </ol>
      <p>
        Refunds will be processed within 5 working days of receiving the refund
        email. Our Customer Service Specialists are ready to assist you—simply
        drop an email on <span className="email">support@codequeue.in</span>.
      </p>

      {/* Refunds: Duplicate Payment Section */}
      <strong>Refunds: Duplicate Payment</strong>
      <p>
        Refund of the duplicate payment made by the delegate will be processed
        via the same source (original method of payment) within 7 working days
        post intimation by the customer.
      </p>

      <span>
        <strong>Note : </strong> All refunds will be processed within 7 working
        days after the refund request is approved by <span className="email">CodeQueue Technologies Pvt Ltd.</span>
      </span>
    </div>
  );
};

export default RefundPolicy;
