import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';

const Layout = ({ children }) => {
    return (
        <>
        
            <Navbar />
            <main>{children}</main>
            <Footer />
        </>
    );
};

export default Layout;
