import React from 'react';
import { useLocation } from 'react-router-dom';

function User({ title, onPrimaryClick, onSecondaryClick }) {
    const location = useLocation();
    const { state } = location;

    const bookingOptions = [
        { title: '1:1 Mentorship', time: '30 mins', type: 'Video Meeting', price: '200+' },
        { title: 'Direct Chat', time: '15 mins', type: 'Text Chat', price: '100+' },
        { title: 'Phone Call', time: '20 mins', type: 'Voice Call', price: '150+' },
    ];

    return (
        <>
            <div className="card mb-4 shadow-sm">
            <div className="row no-gutters">
                <div className="col-md-4">
                    <img src={state?.image} className="card-img" alt={state?.name}/>
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">{state?.name}</h5>
                        <p className="card-text"><strong>Category:</strong> {state?.category}</p>
                        <p className="card-text"><strong>Company:</strong> {state?.company}</p>
                        <p className="card-text"><strong>Experience:</strong> {state?.exp}</p>
                        <p className="card-text"><strong>College:</strong> {state?.college}</p>
                        <p className="card-text"><strong>Degree:</strong> {state?.degree}</p>
                        <p className="card-text"><strong>Skills:</strong> {state?.skills.join(', ')}</p>
                    </div>
                </div>
            </div>
        </div>
            {/* Booking Options */}
            <div className="bookings">
                {bookingOptions.map((option, index) => (
                    <div key={index} className="card">
                        <h3 className="card-title">{option.title}</h3>
                        <div className="card-buttons mt-4">
                            <div className="time">
                                <h6>{option.time}</h6>
                                <p>{option.type}</p>
                            </div>
                            <div className="price">
                                <h6 className="btn" onClick={onSecondaryClick}>{option.price}</h6>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* <AddBooking /> */}
        </>
    );
}



export default User;
