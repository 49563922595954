import React, { useState } from 'react';
import './bookTimeSlot.css'; 
import { useLocation } from 'react-router-dom';

const BookTimeSlot = () => {
  const location = useLocation();
  const { item } = location.state || {};
  const [selectedDate, setSelectedDate] = useState('01 Sep');
  const [selectedTime, setSelectedTime] = useState('10:00 AM');

  const dates = ['01 Sep', '02 Sep', '03 Sep', '04 Sep', '05 Sep'];
  const times = [
    '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM',
    '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM',
    '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM'
  ];

  return (
    <div className="book-time-slot-container">
      <div className="mentorship-details">
        {/* <button className="back-button">←</button> */}
        <div className="mentor-info">
          <h2>{item?.name}</h2>
          <div className="mentor-price">
            <span>₹{item?.price}</span>
            <span>{item?.time}</span>
          </div>
          <p>Do you have a major interview due soon but feel unprepared and anxious? Book a 1:1 call with me where I provide step-by-step actionable and practical tips to move past these momentary blocks and ace your next interview.</p>
        </div>
      </div>

      <div className="time-slot-selection">
        <h3>When should we meet?</h3>
        <div className="date-selection">
          {dates.map((date) => (
            <button
              key={date}
              className={`date-button ${date === selectedDate ? 'selected' : ''}`}
              onClick={() => setSelectedDate(date)}
            >
              {date}
            </button>
          ))}
        </div>

        <h3>Select time of day</h3>
        <div className="time-selection">
          {times.map((time) => (
            <button
              key={time}
              className={`time-button ${time === selectedTime ? 'selected' : ''}`}
              onClick={() => setSelectedTime(time)}
            >
              {time}
            </button>
          ))}
        </div>

        <button className="confirm-button">Confirm Details</button>
      </div>
    </div>
  );
};

export default BookTimeSlot;
