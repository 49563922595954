import React from "react";
import "./Mentor.css";
import { Link } from "react-router-dom";

const mentors = [
  { name: "Satyendra Kumar", designation: "Software Developer" },
  { name: "Satyendra Kumar", designation: "Software Developer" },
  { name: "Satyendra Kumar", designation: "Software Developer" },
  { name: "Satyendra Kumar", designation: "Software Developer" },
  { name: "Satyendra Kumar", designation: "Software Developer" },
  { name: "Satyendra Kumar", designation: "Software Developer" },
];

const Mentors = ({setIsVisible}) => {
  const closeMentorProfile = ()=>{
    setIsVisible(false);
  }
  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>Choose mentor of your choice</h2>
          <div className="popup-content-cards">
            <div className="mentor-cards">
              {mentors.map((mentor, index) => (
                <div className="mentor-card" key={index}>
                  <div className="mentor-image"></div>
                  <h3>{mentor.name}</h3>
                  <p>{mentor.designation}</p>
                  <button className="book-btn">Book Appointment</button>
                </div>
              ))}
            </div>
          </div>
          <div className="flex mentor-btn">
            <Link className="secondary-btn m-button" onClick={closeMentorProfile}>Close</Link>
            <Link to={'/mentors-list'} className="primary-btn m-button">Explore All Mentors</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mentors;
