import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Navbar/Navbar.css";
import "./Login.css";
import { useState } from "react";
import img from "../../assets/sign-in-back.svg";
import { useLocation } from "react-router-dom";
import { handleError, handleSuccess } from "../../Utils";
import axios from "axios";

const CreateNewPassword = () => {
  const [mailOtp, setMailOtp] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Codequeue - New Password";
  }, []);

  const location = useLocation();
  const email = location.state?.email; // Access the email from the state
  const role = location.state?.role;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!mailOtp || !password || !cPassword){
      return handleError("Please fill required fields")
    }
    const otpRegex = /^\d{4}$/;
    if (!otpRegex.test(mailOtp)) {
      return handleError("OTP must be exactly 4 digits");
    }

    if (password.length < 6 || password.length > 12) {
      return handleError("Password must be between 6 and 12 characters.");
    }

    if (password !== cPassword) {
      return handleError("Passwords do not match.");
    }

    try {
      // check if otp is verified 
      await axios.post(
        "http://localhost:4000/api/v1/verify-email-otp",
        { email, mailOtp }
      );

      // update password 
      const response = await axios.post(
        "http://localhost:4000/api/v1/new-password",
        { email, password, role }
      );
      handleSuccess(response.data.message);
      setMailOtp("");
      setPassword("");
      setCPassword("");
      setTimeout(() => {
        navigate("/login");
      }, 2000);

    } catch (error) {
      return handleError(error.response.data.message);
    }

  };

  return (
    <div className="flex container login">
      <div className="left flex-col">
        <div className="left-mid">
          <h1>Update password</h1>
        </div>

        <div>
          <form onSubmit={handleSubmit} className="login-form flex-col">
            <div className="form-group flex-col">
              <label htmlFor="otp">OTP : </label>
              <input
                type="text"
                value={mailOtp}
                placeholder="Enter OTP"
                onChange={(e) => setMailOtp(e.target.value)}
              />
            </div>

            <div className="form-group flex-col">
              <label htmlFor="password">New Password : </label>
              <input
                type="password"
                value={password}
                placeholder="New Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="form-group flex-col">
              <label htmlFor="password">Confirm Password : </label>
              <input
                type="password"
                value={cPassword}
                placeholder="Confirm Password"
                onChange={(e) => setCPassword(e.target.value)}
              />
            </div>

            <button type="submit" className="primary-btn submit-btn">
              Update Password
            </button>
          </form>
        </div>
      </div>

      <div className="right">
        <img src={img} alt="Img" />
      </div>
    </div>
  );
};

export default CreateNewPassword;
