import React, { useEffect } from 'react'
import './Dashboard.css'

import Sidebar from './Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

const Dashboard = () => {
  
  useEffect(() => {
    document.title = "Codequeue - Dashboard";
  }, []);
  return (
    <div className="dashboard">
      <Sidebar />
      <main className="main-content">
        <Outlet/>
      </main>
    </div>
  )
}

export default Dashboard